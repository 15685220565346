/* eslint-disable react-hooks/exhaustive-deps */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:44:59
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-18 10:53:55
 * @FilePath: /MDT-Client/src/pages/user/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { NavBar, List, Empty } from "antd-mobile";
import { useHistory, useParams } from "react-router-dom";
import "../../styles/index.css";
import { hospitalRecordsReport } from '../../services/index'
import Skeleton from '../../components/Skeleton'

function Report() {
  const history = useHistory();
  let params = useParams();
  const { id } = params

  //数据准备
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  //查询我的住院列表
  const _hospitalRecordsReport = async () => {
    const result = await hospitalRecordsReport(id)
    setLoading(false)
    if (result) {
      setCurrentRecord(result.value)
    }
  }

  useEffect(() => {
    _hospitalRecordsReport()
  }, [id])

  return (
    <div className="mdt-container">
      <div className="mdt-header">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">相关报告</div>
        </NavBar>
      </div>
      {
        loading ? <Skeleton /> : (
          <div className="mdt-content">
            {
              currentRecord && currentRecord.length > 0 ? (
                <List header="报告列表">
                  {
                    currentRecord.map((item) => {
                      const { contentName, createdDate, contentId } = item
                      return (
                        <List.Item
                          key={contentId}
                          extra={createdDate}
                          onClick={() => {
                            history.push(`/report-detail/${contentId}`);
                          }}
                        >
                          {contentName}
                        </List.Item>
                      )
                    })
                  }
                </List>
              ) : <Empty
                style={{ marginTop: 200 }}
                imageStyle={{ width: 128 }}
                description='暂无数据'
              />
            }
          </div>
        )
      }
    </div>
  );
}

export default Report;

/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-16 09:31:58
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-25 16:54:01
 * @FilePath: /MDT-Client/src/pages/todo/chart.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import '@chatui/core/es/styles/index.less'
import { NavBar } from 'antd-mobile'
import { useHistory, useParams } from "react-router-dom";
import FileViewer from 'react-file-viewer'
import { dataResourceUrl } from '../../services/index'

export default function () {
  const history = useHistory()
  let params = useParams();
  const { id } = params

  //数据准备
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  const _onError = e => {
    console.log(e, 'error in file-viewer')
  }

  // const _dataResourceUrl = async () => {
  //   const result = await dataResourceUrl(id)
  //   setLoading(false)
  //   if (result) {
  //     setCurrentRecord(result)
  //   }
  // }

  // useEffect(() => {
  //   _dataResourceUrl()
  // }, [id])

  return (
    <div className='mdt-container'>
      <div className='mdt-header mdt-borderBottom'>
        <NavBar
          back='返回'
          onBack={() => {
            history.goBack()
          }}
        >
          <div className='title'>文件预览</div>
        </NavBar>
      </div>
      <div className='mdt-content'>
        {currentRecord && <FileViewer fileType={'docx'} filePath={currentRecord.objectInfo} onError={_onError} />}
      </div>
    </div>
  )
}

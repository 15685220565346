/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-07-19 11:21:53
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { NavBar, Tabs, List, Space, Button, Empty, Dialog, Card, FloatingBubble, Toast } from "antd-mobile";
import { useHistory, useParams } from "react-router-dom";
import { FileCard } from "@chatui/core";
import { mdtCamel, meetingSafeEncode } from '../../services/index'
import { AddOutline, UserSetOutline, } from "antd-mobile-icons";
import { useAliveController, useActivate, useUnactivate } from 'react-activation'

function MeetingDetail() {
  const history = useHistory();
  let params = useParams();
  const { id, type } = params
  const isDoctorPage = window.localStorage.getItem("isDoctorPage");
  const mdtUserInfo = JSON.parse(window.localStorage.getItem("mdtUserInfo"));
  const aliveController = useAliveController()

  //数据准备
  const [currentBaseRecord, setCurrentBaseRecord] = useState(null)//会诊基本信息
  const [currentVideoRecord, setCurrentVideoRecord] = useState(null)//视频会议相关信息
  const [currentEncode, setCurrentEncode] = useState(null)//视频会议加密地址
  const [currentDataResourceRecord, setCurrentDataResourceRecord] = useState(null)//资料
  const [currentPjRecord, setCurrentPjRecord] = useState(null)//评价
  const [currentZdRecord, setCurrentZdRecord] = useState(null)//诊断
  const [activeKey, setActiveKey] = useState("1");//选中的tab

  //不同tab的数据请求
  const _query = async () => {
    switch (activeKey) {
      case '1':
        const result2 = await mdtCamel({
          serId: '7510c943-b619-46c3-8484-66a9ab93177e',
          data: JSON.stringify({
            MDT_ID: id
          })
        })
        if (result2) {
          const record2 = JSON.parse(result2.value)
          console.log({ record2 })
          if (record2.length > 0) {
            setCurrentVideoRecord(record2[0])
            const { CID, PASSWORD, WEBRTCURL } = record2[0]
            const params = {
              param1: CID,
              param2: PASSWORD,
              param3: mdtUserInfo?.XM || mdtUserInfo?.HZ_XM
            }
            // const params = {
            //   param1: '59336960386',
            //   param2: '258456',
            //   param3: 'cs'
            // }
            //console.log({ params })
            const encode = await meetingSafeEncode(params)
            if (encode && WEBRTCURL) {
              const { value } = encode
              const url = `https://ywpt.lxmeeting.com/webrtc/?${value}~/#/meeting`
              console.log({ encode, url })
              setCurrentEncode(url)
            }
          }
        }
        break;
      case '2':
        const result1 = await mdtCamel({
          serId: 'SER10000200',
          data: JSON.stringify({
            MDT_ID: id
          })
        })
        if (result1) {
          const record1 = JSON.parse(result1.value)
          console.log({ record1 })
          setCurrentBaseRecord(record1[0])
        }
        break;
      case '3':
        const result3 = await mdtCamel({
          serId: 'a08f7625-ed6e-455c-9b30-d005d81670da',
          data: JSON.stringify({
            MDT_ID: id
          })
        })
        if (result3) {
          const record3 = JSON.parse(result3.value)
          setCurrentDataResourceRecord(record3)
        }
        break;
      case '4':
        const result4 = await mdtCamel({
          serId: 'SER10000230',
          data: JSON.stringify({
            MDT_ID: id
          })
        })
        const result41 = await mdtCamel({
          serId: 'SER10000210',
          data: JSON.stringify({
            MDT_ID: id
          })
        })
        if (result4 && result41) {
          const record4 = JSON.parse(result4.value)
          console.log({ record4 })
          setCurrentPjRecord(record4[0])
        }
        if (result41) {
          const record41 = JSON.parse(result41.value)
          let arr = []
          record41.map((item) => {
            const { XM, COLUMN_1, COLUMN_2_N, COLUMN_3, HZ_YJ } = item
            arr.push({
              XM,
              zd: `${COLUMN_1} ${COLUMN_2_N} ${COLUMN_3}`,
              jy: HZ_YJ
            })
          })
          console.log({ result41, arr })
          setCurrentZdRecord(arr)
        }
        break;
      default:
        break;
    }
  }

  useActivate(() => {
    _query()
    aliveController.drop(/textArea/)
  })

  useEffect(() => {
    _query()
  }, [id, activeKey])

  const _renderTabContent = () => {
    switch (activeKey) {
      case "1":
        return (
          <div className="mdt-meeting-tab1 mdt-bg-gray mdt-paddar-small">
            {
              currentVideoRecord ? <List>
                <List.Item extra={currentVideoRecord.NAME}>会议名称</List.Item>
                <List.Item extra={currentVideoRecord.PASSWORD}>会议密码</List.Item>
                <List.Item extra={currentVideoRecord.DURATION}>会议时长</List.Item>
                <List.Item extra={currentVideoRecord.BEGINTIME}>会议开始时间</List.Item>
                <List.Item extra={currentVideoRecord.NAME}>会议地址</List.Item>
              </List> :
                <Empty
                  style={{ marginTop: 200 }}
                  imageStyle={{ width: 128 }}
                  description='暂无数据'
                />
            }
            {
              currentVideoRecord && type === 'zl' && <FloatingBubble
                axis="x"
                magnetic="x"
                style={{
                  "--initial-position-bottom": "4rem",
                  "--initial-position-right": "2rem",
                  "--edge-distance": "24px",
                  zIndex: 999
                }}
                className="mdt-hover"
                onClick={async () => {
                  if (currentEncode) {
                    window.location.href = currentEncode
                    // const url = currentEncode.replace('https://ywpt.lxmeeting.com/webrtc/?','')
                    // history.push(`/webView/${url}`)
                  } else {
                    Toast.show({
                      icon: 'error',
                      content: '没有可用的视频地址',
                      position: 'bottom',
                    })
                  }
                }}
              >
                进入
              </FloatingBubble>
            }
          </div>
        );
      case "2":
        return (
          currentBaseRecord && <div className="mdt-meeting-tab1 mdt-bg-gray mdt-paddar-small">
            <List>
              <List.Item extra={currentBaseRecord.MDT_CODE}>会诊编码</List.Item>
              <List.Item extra={currentBaseRecord.MDT_TYPE_N}>会诊类型</List.Item>
              <List.Item extra={currentBaseRecord.XM}>发起人</List.Item>
              <List.Item extra={currentBaseRecord.MDT_C_D}>会诊开始时间</List.Item>
              <List.Item extra={currentBaseRecord.MDT_E_D}>会诊结束时间</List.Item>
              <List.Item extra={currentBaseRecord.MDT_ADDR_N}>会诊地址</List.Item>
              <List.Item extra={currentBaseRecord.MDT_ZZ_N}>会诊主旨</List.Item>
              <List.Item extra='--'>病人入院号</List.Item>
            </List>
            <div className="mdt-paddar-small-t">
              <Card title='入院诊断' >
                --
              </Card>
            </div>
            <div className="mdt-paddar-small-t">
              <Card title='会诊目的' >
                {currentBaseRecord.MDT_MD}
              </Card>
            </div>
          </div>
        );
      case "3":
        return (
          currentDataResourceRecord && <div className="mdt-meeting-tab3">
            <Space direction="vertical" block style={{ "--gap": "1rem" }}>
              {
                currentDataResourceRecord.length > 0 ? currentDataResourceRecord.map((item, index) => {
                  const { FILE_NAME, dataResourceId } = item
                  const file = {
                    name: FILE_NAME,
                    size: 12345,
                  };
                  return (
                    <FileCard key={`FILE_NAME${index}`} file={file} className="mdt-filecard">
                      <Button
                        color="primary"
                        fill="none"
                        size="mini"
                        onClick={() => {
                          history.push(`/file/${dataResourceId}`);
                        }}
                      >
                        查看资料
                      </Button>
                    </FileCard>
                  )
                }) : <Empty
                  style={{ marginTop: 200 }}
                  imageStyle={{ width: 128 }}
                  description='暂无数据'
                />
              }
            </Space>
          </div>
        );
      case "4":
        return (
          currentPjRecord && <div className="mdt-meeting-tab1 mdt-bg-gray mdt-paddar-small">
            <Space direction="vertical" block style={{ "--gap": "1rem" }}>
              <Card title='会诊诊断/建议' >
                {
                  currentZdRecord && currentZdRecord.map((item, index) => {
                    const { XM, zd, jy } = item
                    return (
                      <List key={`zd${index}`} header={`( ${XM} )`}>
                        <List.Item title='诊断:' >
                          <div>{zd}</div>
                        </List.Item>
                        <List.Item title='建议:' >
                          <div className="mdt-list-item-content">{jy}</div>
                        </List.Item>
                      </List>
                    )
                  })
                }
              </Card>
              <Card title='会诊结论' >
                {currentPjRecord.MDT_JL}
              </Card>
              <Card title='会诊评价' >
                {currentPjRecord.MDT_PJ}
              </Card>
            </Space>
            {
              isDoctorPage === 'false' && type === 'zl' && <FloatingBubble
                axis="x"
                magnetic="x"
                style={{
                  "--initial-position-bottom": "4rem",
                  "--initial-position-right": "2rem",
                  "--edge-distance": "24px",
                  zIndex: 999
                }}
                className="mdt-hover"
                onClick={() => {
                  history.push(`/textArea/${id}/hzpj`)
                }}
              >
                <AddOutline fontSize={32} />
              </FloatingBubble>
            }
          </div>
        );
      default:
        break;
    }
  };

  const _renderTabs = () => {
    return (
      <Tabs
        style={{
          "--title-font-size": "0.8rem",
          "--content-padding": "0",
        }}
        activeKey={activeKey}
        onChange={(val) => {
          setActiveKey(val);
        }}
      >
        <Tabs.Tab title="会诊视频" key="1" />
        <Tabs.Tab title="会诊信息" key="2" />
        <Tabs.Tab title="会诊资料" key="3" />
        <Tabs.Tab title="会诊诊断/评价" key="4" />
      </Tabs>
    )
  }

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">会诊详情</div>
        </NavBar>
      </div>
      <div className="mdt-content">
        {_renderTabs()}
        {_renderTabContent()}
      </div>
      <div className="mdt-footer"></div>
    </div>
  );
}

export default MeetingDetail;

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-04-25 11:42:48
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useRef } from 'react'
import "../../styles/index.css";
import { Input, Button, Space, NavBar, Form, Picker } from "antd-mobile";
import { useHistory, useParams } from "react-router-dom";

const basicColumns = [
    [
        { label: '华山医院', value: '1' },
        { label: '仁济医院', value: '2' },
    ]
]

function Regist(props) {
    const [value, setValue] = useState('')
    const [visible, setVisible] = useState(false)
    const history = useHistory();
    let params = useParams();
    const { type } = params
    const formRef = useRef(null)

    //渲染对应角色表单
    const _renderform = () => {
        switch (type) {
            case 'hz':
                return (
                    <Form
                        layout='horizontal'
                        footer={
                            <Button block color='primary' type='submit' size='middle' shape='rounded' onClick={() => {
                                history.push(`/nav`)
                            }}>
                                注册 / 认证
                            </Button>
                        }>
                        <Form.Item label='患者姓名：'>
                            <Input placeholder='请输入内容' />
                        </Form.Item>
                        <Form.Item label='医保卡号：'>
                            <Input placeholder='请输入内容' />
                        </Form.Item>
                        <Form.Item label='身份证号：'>
                            <Input placeholder='请输入内容' />
                        </Form.Item>
                    </Form>
                )
            case 'ys':
                return (
                    <>
                        <Form
                            ref={formRef}
                            layout='horizontal'
                            footer={
                                <Button block color='primary' type='submit' size='middle' shape='rounded' onClick={() => {
                                    history.push(`/nav`)
                                }}>
                                    注册 / 认证
                                </Button>
                            }>
                            <Form.Item label='医生姓名：'>
                                <Input placeholder='请输入内容' />
                            </Form.Item>
                            <Form.Item label='选择医院：' name='hospital' onClick={() => {
                                setVisible(true)
                            }}>
                                <Input placeholder='请选择医院' disabled />

                            </Form.Item>
                            <Form.Item label='员工工号：'>
                                <Input placeholder='请输入内容' />
                            </Form.Item>
                        </Form>
                        <Picker
                            columns={basicColumns}
                            visible={visible}
                            onClose={() => {
                                setVisible(false)
                            }}
                            value={value}
                            onConfirm={v => {
                                const index = basicColumns[0].findIndex((d) => d.value === v[0])
                                console.log({ v, index, basicColumns: basicColumns[0][index].label, formRef })
                                formRef.current.setFieldsValue({ hospital: basicColumns[0][index].label })
                                setValue(v)
                            }}
                        />
                    </>
                )
            default:
                break;
        }
    }

    return (
      <div className="mdt-container">
        <div className="mdt-header">
        </div>
        <div className="mdt-content mdt-paddar">
          <div className="mdt-welecome">
            <div>你好，</div>
            <div>欢迎注册认证MDT</div>
          </div>
          <div className="mdt-form">
            {_renderform()}
            <Space
              direction="vertical"
              className="space"
              style={{ "--gap": "3rem", marginTop: 100 }}
            >
              <div className="link-item">
                <div className="left">已有账号？</div>
                <div
                  className="link"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  去登录
                </div>
              </div>
            </Space>
          </div>
        </div>
      </div>
    );
}

export default Regist
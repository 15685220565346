/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-07 19:50:24
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { Image, NavBar, SearchBar, List, Empty } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams, } from "react-router-dom";
import { mdtCamel, queryDicts } from '../../services/index'

const image =
    "https://dwdw.oss-cn-hangzhou.aliyuncs.com/test/%E6%89%8B%E5%86%8C.jpeg";
function SearchView() {
    const history = useHistory();
    let params = useParams();
    const { type } = params

    //数据准备
    const [currentRecord, setCurrentRecord] = useState(null)
    const [loading, setLoading] = useState(true)

    //查询会诊下拉数据
    const _getZdData = async (keyword) => {
        // let result = await mdtCamel({
        //     serId: 'SER10000250',
        //     data: JSON.stringify({
        //         TYPE: 'diagnosis',
        //     })
        // })
        // setLoading(false)
        // if (result) {
        //     const record = JSON.parse(result.value)
        //     console.log({ record })
        //     const arr = []
        //     record.map((item) => {
        //         const { VALUE, CODE } = item
        //         arr.push({ label: VALUE, value: CODE })
        //     })
        //     setCurrentRecord(arr)
        // }
        let result = await queryDicts('diagnosis', keyword)
        if (result) {
            const arr = []
            const { value } = result
            value.map((item) => {
                const { value, code } = item
                arr.push({ label: value, value: code })
            })
            setCurrentRecord(arr)
        }
    }

    useEffect(() => {
        type === 'zd' && _getZdData()
    }, [])

    return (
        <div className="mdt-container">
            <div className="mdt-header mdt-borderBottom">
                <NavBar
                    back="返回"
                    onBack={() => {
                        history.goBack();
                    }}
                >
                    <div className="title">搜索页</div>
                </NavBar>
            </div>
            <div className="mdt-content mdt-paddar-small">
                <SearchBar
                    placeholder='请输入搜索内容'
                    showCancelButton
                    onSearch={val => {
                        _getZdData(val)
                    }}
                />
                {
                    currentRecord && <List header='搜索结果：'>
                        {
                            currentRecord.length > 0 ? currentRecord.map((item, index) => {
                                const { label } = item
                                return (
                                    <List.Item
                                        key={`searchItem${index}`}
                                        onClick={() => {
                                            window.mdtGobackData = {
                                                type,
                                                data: item
                                            }
                                            history.goBack();
                                        }}>
                                        {label}
                                    </List.Item>
                                )
                            }) : <Empty
                                style={{ marginTop: 200 }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        }
                    </List>
                }
            </div>
        </div>
    );
}

export default SearchView;

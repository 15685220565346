/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-const-assign */
/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-04-18 16:37:13
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useRef, useEffect } from 'react'
import "../../styles/index.css";
import { Input, Button, Space, NavBar } from 'antd-mobile'
import { useHistory, useParams } from "react-router-dom";

function Code(props) {
    const history = useHistory();
    let params = useParams();
    const { id } = params
    const [code, setCode] = useState(['', '', '', ''])
    const ref0 = useRef()
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const disabled = code.findIndex((item) => item === '') !== -1
    let [count, setCount] = useState(60)
    let timer

    useEffect(() => {
        timer = setInterval(() => {
            if (count > 0) {
                setCount((count) => count - 1)
            } else {
                clearInterval(timer)
            }
        }, 1000);
        return () => {
            timer && clearInterval(timer)
        };
    }, [count])

    return (
      <div className="mdt-container">
        <div className="mdt-header">
          <NavBar
            back={`${id === "regist" ? "返回注册" : "返回登录"}`}
            onBack={() => {
              history.goBack();
            }}
          ></NavBar>
        </div>
        <div className="mdt-content">
          <div className="welecome">
            <div>输入验证码</div>
            <div className="desc">已发送至:156183236XX</div>
          </div>
          <div className="form">
            <Space
              direction="vertical"
              className="space"
              style={{ "--gap": "3rem" }}
            >
              <div className="code">
                {code.map((item, index) => {
                  const config = {
                    ref: null,
                  };
                  switch (index) {
                    case 0:
                      config.ref = ref0;
                      config.autoFocus = true;
                      config._focus = () => ref1.current.focus();
                      break;
                    case 1:
                      config.ref = ref1;
                      config._focus = () => ref2.current.focus();
                      break;
                    case 2:
                      config.ref = ref2;
                      config._focus = () => ref3.current.focus();
                      break;
                    case 3:
                      config.ref = ref3;
                      break;
                    default:
                      break;
                  }

                  return (
                    <div className="code-item" key={`code${index}`}>
                      <Input
                        {...config}
                        value={code[index]}
                        type="tel"
                        onChange={(val) => {
                          code[index] = val;
                          setCode(JSON.parse(JSON.stringify(code)));
                          if (val && !code[index + 1]) {
                            if (index === 3) {
                              config.ref.current.blur();
                            } else {
                              config._focus();
                            }
                          }
                        }}
                        onFocus={() => {
                          code[index] = "";
                          setCode(JSON.parse(JSON.stringify(code)));
                        }}
                        maxLength={1}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="btn-item">
                <Button
                  block
                  color="primary"
                  size="middle"
                  shape="rounded"
                  disabled={disabled}
                  onClick={() => {
                    history.push("/home");
                  }}
                >
                  确定
                </Button>
              </div>
              <div className="link-item">
                <div className="count">{count}秒</div>
                <Button
                  color="primary"
                  fill="none"
                  size="mini"
                  disabled={count !== 0}
                  onClick={() => {
                    setCount(60);
                  }}
                >
                  重新发送
                </Button>
              </div>
            </Space>
          </div>
        </div>
      </div>
    );
}

export default Code
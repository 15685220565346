/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-19 14:56:42
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-04-27 18:12:13
 * @FilePath: /MDT-Client/src/components/Pannel.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import "./index.css";

const Pannel = (props) => {
  const { title, data } = props;
  return (
    <div className="mdt-pannel">
      <div className="mdt-pannel-title">{title}</div>
      <div className="mdt-pannel-items">
        {data.map((item,index) => {
            const {label,value,width}=item
            return (
              <div
                key={`mdt-pannel-item${index}`}
                style={{ width: width }}
                className="mdt-pannel-item"
              >
                <div className="mdt-pannel-item-lable">{label}：</div>
                <div className="mdt-pannel-item-value">{value}</div>
              </div>
            );
        })}
        </div>
    </div>
  );
};

export default Pannel;

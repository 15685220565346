/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-25 15:05:14
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-08 17:32:00
 * @FilePath: /MDT-Client/src/pages/hospital/hospital-plan.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:44:59
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-08 17:25:57
 * @FilePath: /MDT-Client/src/pages/user/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Steps, Card, NavBar, Empty } from "antd-mobile";
import {
  UserSetOutline,
  DownlandOutline,
  SearchOutline,
  TextOutline,
  HandPayCircleOutline,
} from "antd-mobile-icons";
import { useHistory, useParams } from "react-router-dom";
import { hospitalRecordsPlan } from '../../services/index'
import Skeleton from '../../components/Skeleton'
import "../../styles/index.css";
const { Step } = Steps;

//flow icon enum
const planIconEnum = {
  'HOSPITALIZATION_PROCEDURES': <TextOutline />,
  "BE_IN_HOSPITAL": <DownlandOutline />,
  "PREOPERATIVE_EXAMINATION": <SearchOutline />,
  "OPERATIVE_TREATMENT": <UserSetOutline />,
  "DISCHARGE": <HandPayCircleOutline />
}

function Plan() {
  const history = useHistory();
  let params = useParams();
  const { id } = params

  //诊疗计划
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  //查询我的住院列表
  const _hospitalRecordsPlan = async () => {
    const result = await hospitalRecordsPlan(id)
    setLoading(false)
    if (result) {
      setCurrentRecord(result.value)
    }
  }

  useEffect(() => {
    _hospitalRecordsPlan()
  }, [id])

  return (

    <div className="mdt-container">
      <div className="mdt-header">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">诊疗计划</div>
        </NavBar>
      </div>
      {
        loading ? <Skeleton /> : (
          <div className="mdt-content mdt-bg-gray mdt-paddar-small">
            {
              currentRecord && currentRecord.length > 0 ? (
                <Card title="诊疗计划" >
                  <Steps direction="vertical" current={2}>
                    {
                      currentRecord && currentRecord.map((item, index) => {
                        const { workEffortName, createdDate, showAsEnumId } = item
                        return (
                          <Step
                            key={`Step${index}`}
                            title={workEffortName}
                            description={createdDate}
                          // icon={planIconEnum[showAsEnumId]}
                          />
                        )
                      })
                    }
                  </Steps>
                </Card>
              ) : <Empty
                style={{ marginTop: 200 }}
                imageStyle={{ width: 128 }}
                description='暂无数据'
              />
            }
          </div>
        )
      }
    </div>
  );
}

export default Plan;

/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:02:24
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-07-19 13:45:13
 * @FilePath: /MDT-Client/src/App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import "./App.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { AliveScope, KeepAlive } from "react-activation";
import AnimatedSwitch from "./components/AnimatedSwitch";
import Index from "./pages/index/index";
import Login from "./pages/login/login";
import Nav from "./pages/login/nav";
import Code from "./pages/login/code";
import Regist from "./pages/login/regist";
import ScanCode from "./pages/login/scan-code";
import Team from "./pages/team/index";
import MeetingDetail from "./pages/meeting/meeting-detail";
import FileViewer from "./pages/other/fileViewer";
import MeetingList from "./pages/meeting/meeting-list";
import MeetingFollow from "./pages/follow/meeting-follow";
import FollowList from './pages/follow/follow-list'
import FollowDetail from './pages/follow/follow-detail'
import MeetingTodo from "./pages/meeting/meeting-todo";
import ChartList from "./pages/chart/chart-list";
import Chart from "./pages/chart/chart";
import HospotalInfo from "./pages/hospital/hospital-info";
import HospotalPlan from "./pages/hospital/hospital-plan";
import HospotalReport from "./pages/hospital/hospital-report";
import ImageDetail from "./pages/other/image-detail";
import ReportDetail from './pages/other/report-detail';
import TextArea from './pages/other/textArea'
import MdtSearchView from './pages/other/searchView'
import WebView from './pages/other/webView'

function App() {
  return (
    <Router>
      <Route exact path="/">
        <Index />
      </Route>
      <Route exact path="/login/:id">
        <Login />
      </Route>
      <Route exact path="/code/:id">
        <Code />
      </Route>
      <Route exact path="/regist/:type">
        <Regist />
      </Route>
      {/* 页面需要缓存 */}
      <AliveScope>
        <Route
          path="/nav"
          render={(props) => (
            <KeepAlive id="nav">
              <Nav {...props} />
            </KeepAlive>
          )}
        />
        <Route
          path="/nav-doctor"
          render={(props) => (
            <KeepAlive id="nav-doctor">
              <Nav {...props} />
            </KeepAlive>
          )}
        />
        <Route
          path="/team/:id"
          render={(props) => (
            <KeepAlive id="team">
              <Team {...props} />
            </KeepAlive>
          )}
        />
        <Route
          path="/chart-list/:id"
          render={(props) => (
            <KeepAlive id="/chart-list">
              <ChartList {...props} />
            </KeepAlive>
          )}
        />
        <Route
          path="/textArea/:id/:type"
          render={(props) => (
            <KeepAlive id="/textArea" name='textArea'>
              <TextArea {...props} />
            </KeepAlive>
          )}
        />
        <Route
          path="/textAreasf/:id/:type"
          render={(props) => (
            <KeepAlive id="/textAreasf" name='textAreasf'>
              <TextArea {...props} />
            </KeepAlive>
          )}
        />
        <Route
          path="/meeting-detail/:id/:hzid/:type"
          render={(props) => (
            <KeepAlive id="/meeting-detail" name='meetingDetail'>
              <MeetingDetail {...props} />
            </KeepAlive>
          )}
        />
        <Route
          path="/meeting-list/:id/:ksid/:userid/:type"
          render={(props) => (
            <KeepAlive id="/meeting-list" name='meetingList'>
              <MeetingList {...props} />
            </KeepAlive>
          )}
        />
      </AliveScope>
      <Route exact path="/follow-detail">
        <FollowDetail />
      </Route>
      <Route exact path="/follow-list/:id">
        <FollowList />
      </Route>
      <Route exact path="/meeting-follow/:id/:ksid/:userid">
        <MeetingFollow />
      </Route>
      <Route exact path="/meeting-todo/:id/:ksid/:userid">
        <MeetingTodo />
      </Route>
      <Route exact path="/hospital-info/:id">
        <HospotalInfo />
      </Route>
      <Route exact path="/hospital-plan/:id">
        <HospotalPlan />
      </Route>
      <Route exact path="/hospital-report/:id">
        <HospotalReport />
      </Route>
      <Route exact path="/chart/:id/:type/:chatId">
        <Chart />
      </Route>
      <Route exact path="/file/:id">
        <FileViewer />
      </Route>
      <Route exact path="/image-detail/:id">
        <ImageDetail />
      </Route>
      <Route exact path="/scan-code/:id">
        <ScanCode />
      </Route>
      <Route exact path="/report-detail/:id">
        <ReportDetail />
      </Route>
      <Route exact path="/searchView/:type">
        <MdtSearchView />
      </Route>
      <Route exact path="/webView/:url">
        <WebView />
      </Route>
    </Router>
  );
}

export default App;

/* eslint-disable no-dupe-keys */
/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-07-19 13:41:34
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import "../../styles/index.css";
import {
  Image,
  Grid,
  Swiper,
  Badge,
  Picker,
  Button,
  Space,
  Modal,
  Dialog,
} from "antd-mobile";
import { useHistory } from "react-router-dom";
import {
  TeamOutline,
  FileOutline,
  VideoOutline,
  UserContactOutline,
  UnorderedListOutline,
  ChatAddOutline,
  CalendarOutline,
  EditSOutline,
  ContentOutline,
  CheckShieldOutline,
  UpOutline,
  DownOutline,
  FaceRecognitionOutline,
  CloseShieldOutline,
  KoubeiOutline,
  ShopbagOutline
} from "antd-mobile-icons";
import {
  patientHospitalRecordList,
  getChatList,
  readMsg,
  logout,
  getMdtListPending,
  queryMe,
  getBannerList,
  mdtCamel
} from "../../services/index";
import { useAliveController, useActivate, useUnactivate } from "react-activation";

function Nav() {
  const history = useHistory();
  const { location } = history;
  const aliveController = useAliveController()
  const loginPath =
    location.pathname === "/nav-doctor" ? `/login/doctor` : "/scan-code/xxx";

  //是否登录参数
  const [isLogin, setIsLogin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentBanner, setCurrentBanner] = useState(null)
  const [currentTeamList, setCurrentTeamList] = useState(null)
  const [teamVisible, setTeamVisible] = useState(false);
  let [currentTeamValue, setCurrentTeamValue] = useState(null);

  //住院信息下拉相关参数
  const [hospitalRecordVisible, setHospitalRecordVisible] = useState(false);
  const [hospitalRecordValue, setHospitalRecordValue] = useState(null);
  const [hospitalRecordList, setHospitalRecordList] = useState(null);
  const [pendingCount, setPendingCount] = useState(null);
  const [readState, setReadState] = useState(null);

  //退出登录
  const _logout = async () => {
    const result = await logout()
    if (result) {
      setIsLogin(false)
      setCurrentTeamList(null)
      setReadState(null)
      setPendingCount(null)
      setHospitalRecordValue(null)
      setHospitalRecordList(null)
    }
  }

  //进入对应应用
  const _pushApp = async (url, needed = false) => {
    if (isLogin || needed) {
      history.push(`${url}`);
    } else {
      const result = await Dialog.confirm({
        content: "使用当前功能，需要您登录认证",
      });
      if (result) {
        aliveController.clear()
        history.push(loginPath);
      }
    }
  };

  //根据当前用户的应用权限信息 判断是否隐藏应用
  const _hidden = (permissionId) => {
    return (
      currentUser &&
      currentUser.SecurityPermission &&
      currentUser.SecurityPermission.findIndex(
        (item) => item.permissionId === permissionId
      ) === -1
    );
  };

  //launchPad 应用初始化清单
  const doctor = [
    {
      group: "groupA",
      groupName: "服务介绍",
      items: [
        {
          permissionId: "Team_Introduction",
          text: "团队介绍",
          icon: <TeamOutline fontSize={26} color="#fff" />,
          hidden: _hidden("Team_Introduction"),
          onClick: () => {
            _pushApp("/team/doctor", true);
          },
        },
        {
          permissionId: "User_Manual",
          text: "使用手册",
          icon: <ContentOutline fontSize={26} color="#fff" />,
          hidden: _hidden("User_Manual"),
          onClick: () => {
            _pushApp("/image-detail/doctor", true);
          },
        },
        {
          permissionId: "Login_Auth",
          text: "登录认证",
          icon: <CheckShieldOutline fontSize={26} color="#fff" />,
          hidden: isLogin,
          onClick: () => {
            _pushApp(loginPath, true);
          },
        },
        {
          permissionId: "Login_Auth",
          text: "退出登录",
          icon: <CloseShieldOutline fontSize={26} color="#fff" />,
          hidden: !isLogin,
          onClick: () => {
            Modal.confirm({
              title: "提示",
              content: `是否确定退出登录`,
              onConfirm: async () => {
                _logout()
              },
              onCancel: async () => {
              },
            });
          },
        },
      ],
    },
    {
      group: "groupB",
      groupName: "门诊服务",
      renderPicker: currentTeamList && (
        <>
          <Button
            size="mini"
            onClick={() => {
              setTeamVisible(true);
            }}
          >
            <Space>
              <span>
                {
                  currentTeamList.length > 0 && currentTeamList[0][
                    currentTeamList[0].findIndex(
                      (item) => item.value === currentTeamValue
                    )
                  ].label
                }
              </span>
              {teamVisible ? <UpOutline /> : <DownOutline />}
            </Space>
          </Button>
          <Picker
            title="请选择当前团队"
            columns={currentTeamList}
            visible={teamVisible}
            onClose={() => {
              setTeamVisible(false);
            }}
            value={currentTeamValue}
            onConfirm={(v) => {
              setCurrentTeamValue(v[0]);
            }}
          />
        </>
      ),
      items: [
        {
          permissionId: "Consultation_History",
          text: "会诊归档",
          icon: <CalendarOutline fontSize={26} color="#fff" />,
          hidden: _hidden("Consultation_History"),
          onClick: () => {
            _pushApp(`/meeting-list/doctor/${currentTeamValue}/${currentUser && currentUser.externalId}/gd`);
          },
        },
        {
          permissionId: "Pending_Consultation",
          text: "会诊诊疗",
          icon: <VideoOutline fontSize={26} color="#fff" />,
          badge: pendingCount,
          hidden: _hidden("Pending_Consultation"),
          onClick: () => {
            _pushApp(`/meeting-list/doctor/${currentTeamValue}/${currentUser && currentUser.externalId}/zl`);
          },
        },
        {
          permissionId: "Consultation_Evaluation",
          text: "会诊评价",
          icon: <FaceRecognitionOutline fontSize={26} color="#fff" />,
          hidden: _hidden("Consultation_Evaluation"),
          onClick: () => {
            _pushApp(`/meeting-list/doctor/${currentTeamValue}/${currentUser && currentUser.externalId}/pj`);
          },
        },
        {
          permissionId: "FollowUp_Records",
          text: "随访记录",
          icon: <EditSOutline fontSize={26} color="#fff" />,
          hidden: _hidden("FollowUp_Records"),
          onClick: () => {
            _pushApp(`/follow-list/${currentUser?.externalId}`);
          },
        },
      ],
    },
    {
      group: "groupC",
      groupName: "住院服务（0.46.6）",
      items: [
        {
          permissionId: "Online_Consult",
          text: "在线咨询",
          icon: <ChatAddOutline fontSize={26} color="#fff" />,
          badge: readState && readState.noRead !== 0 ? readState.noRead : null,
          hidden: _hidden("Online_Consult"),
          onClick: () => {
            _pushApp("/chart-list/doctor");
          },
        },
      ],
    },
  ];

  const patient = [
    {
      group: "groupA",
      groupName: "服务介绍",
      items: [
        {
          permissionId: "Team_Introduction",
          text: "团队介绍",
          icon: <TeamOutline fontSize={26} color="#fff" />,
          hidden: _hidden("Team_Introduction"),
          onClick: () => {
            _pushApp("/team/xxx", true);
          },
        },
        {
          permissionId: "User_Manual",
          text: "使用手册",
          icon: <ContentOutline fontSize={26} color="#fff" />,
          hidden: _hidden("User_Manual"),
          onClick: () => {
            _pushApp("/image-detail/xxx", true);
          },
        },
        {
          permissionId: "Login_Auth",
          text: "登录认证",
          icon: <CheckShieldOutline fontSize={26} color="#fff" />,
          hidden: isLogin,
          onClick: () => {
            _pushApp(loginPath, true);
          },
        },
        {
          permissionId: "Login_Auth",
          text: "退出登录",
          icon: <CloseShieldOutline fontSize={26} color="#fff" />,
          hidden: !isLogin,
          onClick: () => {
            Modal.confirm({
              title: "提示",
              content: `是否确定退出登录`,
              onConfirm: async () => {
                _logout()
              },
              onCancel: async () => {
              },
            });
          },
        },
      ],
    },
    {
      group: "groupB",
      groupName: "门诊服务",
      items: [
        {
          permissionId: "Consultation_History",
          text: "会诊归档",
          icon: <CalendarOutline fontSize={26} color="#fff" />,
          hidden: _hidden("Consultation_History"),
          onClick: () => {
            _pushApp(`/meeting-list/xxx/xxx/${currentUser && currentUser.externalId}/gd`);
          },
        },
        {
          permissionId: "Pending_Consultation",
          text: "会诊诊疗",
          icon: <VideoOutline fontSize={26} color="#fff" />,
          badge: pendingCount,
          hidden: _hidden("Pending_Consultation"),
          onClick: () => {
            _pushApp(`/meeting-list/xxx/xxx/${currentUser && currentUser.externalId}/zl`);
          },
        },
        {
          permissionId: "FollowUp_Records",
          text: "随访记录",
          icon: <EditSOutline fontSize={26} color="#fff" />,
          hidden: _hidden("FollowUp_Records"),
          onClick: () => {
            _pushApp(`/follow-list/${currentUser?.externalId}`);
          },
        },
      ],
    },
    {
      group: "groupC",
      groupName: "住院服务（0.46.6）",
      renderPicker: hospitalRecordValue && (
        <>
          <Button
            size="mini"
            onClick={() => {
              setHospitalRecordVisible(true);
            }}
          >
            <Space>
              <span>
                {
                  hospitalRecordList && hospitalRecordList[0][
                    hospitalRecordList[0].findIndex(
                      (item) => item.value === hospitalRecordValue
                    )
                  ].label
                }
              </span>
              {hospitalRecordVisible ? <UpOutline /> : <DownOutline />}
            </Space>
          </Button>
          <Picker
            title="请选择查看的住院记录"
            columns={hospitalRecordList}
            visible={hospitalRecordVisible}
            onClose={() => {
              setHospitalRecordVisible(false);
            }}
            value={hospitalRecordValue}
            onConfirm={(v) => {
              setHospitalRecordValue(v[0]);
            }}
          />
        </>
      ),
      items: [
        {
          permissionId: "Hospitalization_Info",
          text: "住院信息",
          icon: <UserContactOutline fontSize={26} color="#fff" />,
          hidden: _hidden("Hospitalization_Info"),
          disabled: !hospitalRecordValue || hospitalRecordValue.length === 0,
          onClick: () => {
            _pushApp(`/hospital-info/${hospitalRecordValue}`);
          },
        },
        {
          permissionId: "Treatment_Plan",
          text: "诊疗计划",
          icon: <UnorderedListOutline fontSize={26} color="#fff" />,
          hidden: _hidden("Treatment_Plan"),
          disabled: !hospitalRecordValue || hospitalRecordValue.length === 0,
          onClick: () => {
            _pushApp(`/hospital-plan/${hospitalRecordValue}`);
          },
        },
        {
          permissionId: "Report_Query",
          text: "报告查询",
          icon: <FileOutline fontSize={26} color="#fff" />,
          hidden: _hidden("Report_Query"),
          disabled: !hospitalRecordValue || hospitalRecordValue.length === 0,
          onClick: () => {
            _pushApp(`/hospital-report/${hospitalRecordValue}`);
          },
        },
        {
          permissionId: "Online_Consult",
          text: "在线咨询",
          icon: <ChatAddOutline fontSize={26} color="#fff" />,
          badge: readState && readState.noRead !== 0 ? readState.noRead : null,
          hidden: _hidden("Online_Consult"),
          disabled: !hospitalRecordValue || hospitalRecordValue.length === 0,
          onClick: async () => {
            if (readState && readState.noRead > 0) {
              await readMsg({ workEffortId: hospitalRecordValue });
            }
            _pushApp(
              `/chart/${hospitalRecordValue}/xxx/${readState ? readState.list[0].chatId : null
              }`
            );
          },
        },
        {
          permissionId: "Report_Query",
          text: "康复治疗-未开放",
          icon: <KoubeiOutline fontSize={26} color="#fff" />,
          hidden: false,
          disabled: true,
        },
        {
          permissionId: "Report_Query",
          text: "手术查询-未开放",
          icon: <ShopbagOutline fontSize={26} color="#fff" />,
          hidden: false,
          disabled: true,
        },
      ],
    },
  ];

  //根据用户访问路由 判断当前用户的角色 以及输出的初始化应用清单
  const isDoctorPage = location.pathname === "/nav"
  let data = isDoctorPage ? patient : doctor;

  //查询banner 信息
  const _getBannerList = async () => {
    const result = await getBannerList()
    if (result) {
      setCurrentBanner(result.value)
    }
  }

  //查询我的住院列表
  const _patientHospitalRecordList = async () => {
    const result = await patientHospitalRecordList();
    if (result) {
      const { value } = result;
      const arr = [[]];
      value.map((item, index) => {
        const { workEffortId, workEffortName } = item;
        arr[0].push({
          label: workEffortName,
          value: workEffortId,
        });
        //默认设置第一个住院记录
        if (index === 0) {
          setHospitalRecordValue(workEffortId);
          _getChatList(workEffortId)
        }
      });
      setHospitalRecordList(arr);
    }
  };

  //查询我的住院待办数量
  const _getChatList = async (id) => {
    const result = await getChatList(id);
    if (result?.list) {
      setReadState(result);
    } else {
      setIsLogin(false)
    }
  };

  //查询我的住院待办数量
  const _getMdtListPending = async (id) => {
    const result = await getMdtListPending();
    if (result) {
      const resord = JSON.parse(result.value)
      setPendingCount(resord.length);
    }
  };

  //查询院内端用户信息
  const _queryMdtUserInfo = async (id) => {
    let params
    if (isDoctorPage) {
      params = {
        serId: 'SER10000140',
        data: JSON.stringify({
          HZ_ID: id
        })
      }
    } else {
      params = {
        serId: 'SER10000160',
        data: JSON.stringify({
          USER_ID: id,
          // KS_ID:currentTeamValue
        })
      }
    }
    const result = await mdtCamel(params)
    if (result) {
      const resord = JSON.parse(result.value)
      console.log({ mdtUserInfo: resord[0], result })
      window.localStorage.setItem("mdtUserInfo", JSON.stringify(resord[0]));
    }
  }

  //查询用户信息
  const _queryMe = async () => {

    //处理演示时的登录问题
    const mdtPath = window.localStorage.getItem("mdtPath");
    if (!mdtPath) {
      window.localStorage.setItem("mdtPath", location.pathname);
    } else {
      if (location.pathname !== mdtPath) {
        window.localStorage.removeItem("mdtPath");
        console.log({ mdtPath, path: location.pathname, isLogin })
        await _logout();
        aliveController.clear()
      }
    }

    //查询用户信息
    const result = await queryMe();
    if (result) {
      const { externalId } = result
      await _queryMdtUserInfo(externalId)
      window.localStorage.setItem("mdtExternalId", externalId);
      window.localStorage.setItem("isDoctorPage", isDoctorPage);
      setCurrentUser(result);
      setIsLogin(true);
      externalId && !isDoctorPage && _queryMyTeam(externalId)
      isDoctorPage && await _patientHospitalRecordList();
      isLogin && await _getMdtListPending();
    }

    //查询banner
    if (!currentBanner) {
      _getBannerList()
    }
  };

  //查看我的团队列表
  const _queryMyTeam = async (id) => {
    const params = {
      serId: 'SER10000170',
      data: JSON.stringify({
        USER_ID: id
      })
    }
    const result = await mdtCamel(params)
    if (result) {
      const resord = JSON.parse(result.value)
      if (resord.length > 0) {
        currentTeamValue = resord[0].KS_ID
        const arr = [[]];
        resord.map((item, index) => {
          const { KS_NAME, KS_ID } = item;
          arr[0].push({
            label: KS_NAME,
            value: KS_ID,
          });
          if (index === 0) {
            setCurrentTeamValue(KS_ID);
          }
        });
        setCurrentTeamList(arr)
      }
    }
  };

  //监听页面活跃
  useActivate(() => {
    console.log('useActivate==>')
    if (!isLogin) {
      _queryMe();
    } else {
      !isDoctorPage ? _getChatList() : _getChatList(hospitalRecordValue)
    }
    aliveController.drop(/meetingList/)
    aliveController.drop(/meetingDetail/)
  })

  useEffect(() => {
    if (!isLogin) {
      _queryMe();
    }
  }, []);

  return (
    <div className="mdt-container">
      <div className="mdt-header"></div>
      <div className="mdt-content">
        <div>
          <Swiper loop>{
            currentBanner && currentBanner.map((item, index) => {
              const { ImageDataResource } = item
              const { origin } = window.location
              const image = `${origin}/mdt/control/odataAppSvc/mdtSystemManage/ImageDataResources('${ImageDataResource.dataResourceId}')/$value`
              return (
                <Swiper.Item
                  key={index}
                // onClick={() => {
                //   _pushApp("/image-detail/xxx", true);
                // }}
                >
                  <Image src={image} fit="cover" height={200} />
                </Swiper.Item>
              )
            })
          }</Swiper>
        </div>
        {data.map((item) => {
          const { group, groupName, items, renderPicker, hidden } = item;
          return (
            !hidden && <div key={group} className="mdt-nav">
              <div className="mdt-nav-goup">
                <div className="mdt-nav-group-name">{groupName}</div>
                {renderPicker}
              </div>
              <Grid columns={3} gap={8}>
                {items.map((citem, cindex) => {
                  const { text, icon, badge, onClick, disabled, hidden } =
                    citem;
                  return (
                    !hidden && (
                      <Grid.Item
                        key={`GridItem${cindex}`}
                        onClick={() => {
                          !disabled && onClick();
                        }}
                      >
                        <div
                          className={`mdt-nav-item ${disabled ? "mdt-disabled" : ""
                            }`}
                        >
                          <Badge content={badge}>
                            <div className="mdt-nav-item-icon">{icon}</div>
                          </Badge>
                          <span>{text}</span>
                        </div>
                      </Grid.Item>
                    )
                  );
                })}
              </Grid>
            </div>
          );
        })}
      </div>
      <div className="mdt-footer"></div>
    </div>
  );
}

export default Nav;

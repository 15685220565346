/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-19 18:32:58
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react'
import "../../styles/index.css";
import { Input, Button, Space, ActionSheet, NavBar } from 'antd-mobile'
import { UserOutline, LockOutline } from 'antd-mobile-icons'
import { useHistory, useParams } from "react-router-dom";
import { login } from '../../services/index'

const actions = [
  { text: '我是患者', key: 'hz' },
  { text: '我是医生', key: 'ys' },
]

function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [actionSheetVisible, setActionSheetVisible] = useState(false)
  const history = useHistory();
  let params = useParams();
  const { id } = params
  const navPath = id === 'doctor' ? '/nav-doctor' : '/nav'

  const _login = async () => {
    const params = {
      username,
      password,
    };

    const result = await login(params)
    if (result) {
      history.replace(navPath);
    }
  }

  return (
    <div className="mdt-container">
      <div className="mdt-header">
        <div className="mdt-header mdt-borderBottom">
          <NavBar
            back="返回"
            onBack={() => {
              history.goBack();
            }}
          >
            <div className="title">登录认证</div>
          </NavBar>
        </div>
      </div>
      <div className="mdt-content mdt-paddar">
        <div className="mdt-welecome">
          <div>你好，</div>
          <div>欢迎使用MDT诊疗系统</div>
        </div>
        <div className="mdt-form">
          <Space
            direction="vertical"
            className="space"
            style={{ "--gap": "1rem" }}
          >
            <div className="icon-item">
              <UserOutline className="icon" />
              <Input
                placeholder="请输入登录账号"
                value={username}
                type="text"
                onChange={(val) => {
                  setUsername(val);
                }}
                maxLength={20}
              />
            </div>
            <div className="icon-item">
              <LockOutline className="icon" />
              <Input
                placeholder="请输入登录密码"
                value={password}
                type="password"
                onChange={(val) => {
                  setPassword(val);
                }}
                maxLength={20}
              />
            </div>
            <div className="btn-item">
              <Button
                block
                color="primary"
                size="middle"
                shape="rounded"
                disabled={!username || !password}
                onClick={_login}
              >
                登录
              </Button>
            </div>
            <div className="link-item">
              <div className="left">账号密码</div>
              <div
                className="link"
                // onClick={() => {
                //   history.push("/scan-code/xxx");
                // }}
              >
                登录认证
              </div>
            </div>
          </Space>
          <ActionSheet
            extra="请选择您要认证的角色"
            cancelText="取消"
            visible={actionSheetVisible}
            actions={actions}
            onClose={() => setActionSheetVisible(false)}
            onAction={(action) => {
              const { key } = action;
              history.push(`/regist/${key}`);
            }}
          />
        </div>
      </div>
      {/* <div className="mdt-footer mdt-paddar">@ 登录 / 认证1.0</div> */}
    </div>
  );
}

export default Login
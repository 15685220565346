/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import "@chatui/core/es/styles/index.less";
import { useMessages, List, ListItem } from "@chatui/core";
import { NavBar } from "antd-mobile";
import { useHistory, useParams } from "react-router-dom";
import "@chatui/core/dist/index.css";
import { getMsg, sendMsg, getHistoryMsg, hospitalRecordsReport } from '../../services/index'
import "../../styles/index.css";

export default function Chart() {
  const wrapper = useRef();
  const history = useHistory();
  let params = useParams();
  const { id, type,chatId } = params
  let [currentMsgSequence, setCurrentMsgSequence] = useState(null)
  let [historyMsgSequence, setHistoryMsgSequence] = useState(null)
  const [currenRecord, setCurrenRecord] = useState(null)
  let [reportRecord, setReportRecord] = useState(null)
  let [autoCallBack, setAutoCallBack] = useState(true)
  let [bot, setBot] = useState(null)
  let timer

  //查询全部咨询数据
  const _getHistoryMsg = async (bot) => {
    const result = await getHistoryMsg(chatId)
    if (result) {
      setCurrenRecord(result)
      const { list } = result
      const ctx = bot.getCtx();
      list.map((item, index) => {
        ctx.appendMessage(item);
        if (index === list.length - 1) {
          currentMsgSequence = item._id
          setCurrentMsgSequence(currentMsgSequence)
        }
        if (index === 0) {
          historyMsgSequence = item._id
          setHistoryMsgSequence(historyMsgSequence)
        }
      })
    }
  }

  //查询标记后的咨询数据
  const _getMsg = async (msgSequence) => {
    const result = await getMsg(chatId, msgSequence)
    if (result) {
      const ctx = bot.getCtx();
      result.map((item, index) => {
        ctx.appendMessage(item);
        if (index === result.length - 1) {
          currentMsgSequence = item._id
          setCurrentMsgSequence(currentMsgSequence)
        }
      })
    }
  }

  //自定义卡片
  const MyCard=({ data, ctx, meta })=>{
    console.log(data, ctx, meta);
    console.log({ reportRecord });
    return (
      <List className='mdt-cust-cardList'>
        {
          reportRecord && reportRecord.map((item,index)=>{
            console.log({ item })
            return <ListItem key={`cust${index}`} content="内容1" rightIcon='chevron-right' onClick={() => {}} />
          })
        }
      </List>
    );
  }

  //查询我的报告
  const _hospitalRecordsReport = async () => {
    const result = await hospitalRecordsReport(id)
    if (result) {
      const { value } = result
      const arr=[]
      value.map((item)=>{
        const { contentName, contentId }=item
        arr.push({
          title: `点击咨询：${contentName}`,
          content: `我想咨询我的报告：${contentName} https://mdt.banff-tech.com/#/report-detail/${contentId} 点击链接查看`
        },)
      })
      reportRecord=arr
      setReportRecord(reportRecord)
    }
  }

  //bot初始化
  useEffect(() => {
    if (!bot && reportRecord) {
      bot = new window.ChatSDK({
        root: wrapper.current,
        config: {
          // （可选）配置按钮文案
          loadMoreText: '点击加载更多',
          // 头像白名单
          avatarWhiteList: ["knowledge", "recommend"],
          // 机器人信息
          robot: {
            avatar:
              "https://bpic.51yuansu.com/pic3/cover/03/74/75/5bf7e4c23f34d_610.jpg",
          },
          user: {},
          // 首屏消息
          messages: type === 'doctor' ? [] : [
            {
              type: "system",
              content: {
                text: "智能助理进入对话，为您服务",
              },
            },
            {
              type: "text",
              content: {
                text: "MDT智能助理为您服务，请发送需要咨询的问题，我们会及时联系医生进行回复！",
              },
            },
          ],
          // 快捷短语
          quickReplies: type !== 'doctor' ? [
            {
              name: '引用报告',
              type: 'card',
              card: {
                code: 'promotion', data: {
                  array: [
                    {
                      type: 'recommend',
                      list: reportRecord
                    },
                  ]
                }
              },
            },
            // {
            //   name: '引用报告',
            //   type: 'card',
            //   card: { code: 'my-card' },
            // },
          ] : [],
          // jsbridge 配置
          bridge: {
            takePhoto(opts) { }, // 选择图片
            previewImage(data) { }, // 预览大图
          },
          // 输入框占位符
          placeholder: "请输入您想咨询的内容",
          //工具栏
          toolbar: [
            {
              type: "image",
              icon: "image",
              title: "相册",
            },
          ],
        },
        handlers: {
          onToolbarClick: (item, ctx) => {
            // 如果点的是“相册”
            if (item.type === "image") {
              ctx.util.chooseImage({
                // multiple: true, // 是否可多选
                async success(e) {
                  if (e.files) {
                    // 如果有 h5 上传的图
                    const file = e.files[0];
                    // 先展示图片
                    ctx.appendMessage({
                      type: "image",
                      content: {
                        picUrl: URL.createObjectURL(file),
                      },
                      position: "right",
                    });

                    const params = {
                      chatId,
                      msgType: 'image',
                      msgData: file
                    }
                    const result = await sendMsg(params)
                    if (result) {
                      const { msgSequence } = result
                      setCurrentMsgSequence(msgSequence)
                    }

                    // 发起请求，具体自行实现，这里以 OCR 识别后返回文本为例
                    // requestOcr({ file }).then((res) => {
                    //   ctx.postMessage({
                    //     type: "text",
                    //     content: {
                    //       text: res.text,
                    //     },
                    //     quiet: true, // 不展示
                    //   });
                    // });
                  } else if (e.images) {
                    // 如果有 app 上传的图
                    // ..与上面类似
                  }
                },
              });
            }
          },
          /**
           *
           * 解析请求返回的数据
           * @param {object} res - 请求返回的数据
           * @param {object} requestType - 请求类型
           * @return {array}
           */
          parseResponse: function (res, requestType) {
            console.log({ res, requestType });
            // 根据 requestType 处理数据
            if (requestType === "send" && res && res.Messages) {
              // 用 isv 消息解析器处理数据
              //return isvParser({ data: res });
            }

            if (requestType === "history" && res) {
              const { list, noMore } = res
              if (list.length > 0) {
                historyMsgSequence = list[0]._id
                setHistoryMsgSequence(historyMsgSequence)
              }
            }

            // 不需要处理的数据直接返回
            return res;
          },
          track: function (data) {
            console.log(data.eventType);
          },
        },
        requests: {
          /**
           *
           * 问答接口
           * @param {object} msg - 消息
           * @param {string} msg.type - 消息类型
           * @param {string} msg.content - 消息内容
           * @return {object}
           */
          send: async function (msg) {
            console.log({ msg });
            const data = msg.content;

            //前端的自动回复
            //const ctx = bot.getCtx();
            // ctx && ctx.appendMessage({
            //   type: "text",
            //   content: { text: "请稍等，稍后有专家解答您的问题！" },
            //   position: "left",
            // });

            //发送文本消息时
            if (msg.type === "text") {
              const params = {
                chatId,
                msgType: 'text',
                msgData: data.text
              }
              const result = await sendMsg(params)
              if (result) {
                const { msgSequence } = result
                setCurrentMsgSequence(msgSequence)
              }

              if (type !== 'doctor' && autoCallBack) {
                autoCallBack = false
                setAutoCallBack(autoCallBack)
                return {
                  type: "text",
                  content: { text: "请稍等，稍后有专家解答您的问题！" },
                  position: "left",
                }
              }
            }

            //发送图片image
            if (msg.type === "text") {
              // const params = {
              //    chatId,
              //   msgType: 'text',
              //   msgData: data.text
              // }
              // const result = await sendMsg(params)
            }
          },
          // 配置接口
          history: async function () {
            return await getHistoryMsg(chatId, historyMsgSequence)
          },
        },
        components: {
          'my-card': MyCard,
          // 这样注册 会有一个隐私的转化 adaptable-action-card 会到全局上 取 AlimeComponentAdaptableActionCard 对象
          'adaptable-action-card': '//g.alicdn.com/alime-components/adaptable-action-card/0.1.7/index.js',
          // 推荐主动指定 name 属性
          'adaptable-action-card': {
            name: 'AlimeComponentAdaptableActionCard',
            url: '//g.alicdn.com/alime-components/adaptable-action-card/0.1.7/index.js'
          },
        }
      });
      bot.run();
    }

    if (!currenRecord && bot) {
      _getHistoryMsg(bot)
    }

    if (currentMsgSequence && bot) {
      timer = setInterval(() => {
        _getMsg(currentMsgSequence)
      }, 3000);
    }

    if (!reportRecord){
      _hospitalRecordsReport()
    }

    return () => {
      timer && clearInterval(timer)
    };
  }, [currentMsgSequence, reportRecord]);

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">住院远程咨询</div>
        </NavBar>
      </div>
      <div className="mdt-content">
        <div style={{ height: "100%" }} ref={wrapper} />
      </div>
      <div className="mdt-footer"></div>
    </div>
  );
}

/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Skeleton } from 'antd-mobile'
import "./index.css";

export default () => {
    return (
        <div className='mdt-paddar-small'>
            <Skeleton.Title animated />
            <Skeleton.Paragraph lineCount={5} animated />
            <Skeleton.Title animated />
            <Skeleton.Paragraph lineCount={5} animated />
            <Skeleton.Paragraph lineCount={5} animated />
        </div>
    )
}
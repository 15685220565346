/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-25 16:46:54
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-02 09:03:12
 * @FilePath: /MDT-Client/src/pages/other/scan-code.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import Html5QrcodePlugin from "../../components/Html5Scan";
import { NavBar, Modal, Toast } from "antd-mobile";
import { useHistory, useParams } from "react-router-dom";
import { qrLogin } from '../../services/index'

const Scan = (props) => {
  const history = useHistory();
  let params = useParams();
  const { id } = params
  const navPath = id === 'doctor' ? '/nav-doctor' : '/nav'
  let show = true;

  //扫描到二维码
  const onNewScanResult = (decodedText, decodedResult) => {
    if (show){
      show=false
      _qrLogin(decodedText)
    }
    // if (show) {
    //   show = false;
    //   Modal.confirm({
    //     title: "扫码结果",
    //     content: `${decodedText}`,
    //     onConfirm: async () => {
    //       show = true;
    //       _qrLogin(decodedText)
    //     },
    //     onCancel: async () => {
    //       show = true;
    //     },
    //   });
    // }
  };

  const _qrLogin = async (code) => {
    const params = {
      code,
    }

    const result = await qrLogin(params)
    if (result) {
      show = true
      Toast.show({
        icon: 'success',
        content: '登录成功',
        position: 'bottom',
        duration:3000
      })
      history.replace(navPath);
    }else{
      show = true
    }
  }

  return (
    <div className="mdt-container">
      <div className="mdt-header">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">扫码手环二维码登录</div>
        </NavBar>
      </div>
      <div className="mdt-content">
        <Html5QrcodePlugin qrCodeSuccessCallback={onNewScanResult} />
      </div>
    </div>
  );
};

export default Scan;

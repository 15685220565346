/* eslint-disable react-hooks/rules-of-hooks */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-05-06 11:37:55
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-02 09:02:24
 * @FilePath: /MDT-Client/src/services/request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import axios from 'axios'; // 引入axios
import { Toast } from 'antd-mobile'

let HttpRequest = axios.create({
    withCredentials: true,
})

/**
 * 请求错误处理
 * @param {*} err 
 */
const errHandle = (err, url) => {

    const { response } = err
    if (response) {
        const { status, data } = response
        switch (status) {
            case 401:
                if (url.search('PatientHospitalRecordList') === -1) {
                    Toast.show({
                        icon: 'fail',
                        content: '用户认证失败,刷新或重新进入！',
                    })
                }
                break;
            case 500:
                if (url.search('PatientHospitalRecordList') === -1) {
                    const errorMessage = data?.error?.message || data?._ERROR_MESSAGE_
                    Toast.show({
                        icon: 'fail',
                        content: errorMessage ? errorMessage :'服务调用失败',
                        duration: 5000
                    })
                }
                break;
            default:
                break;
        }
    }
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, loading = false) {
    if (loading) {
        Toast.show({
            icon: 'loading',
            content: '加载中…',
            duration: 0
        })
    }

    return new Promise((resolve, reject) => {
        HttpRequest.get(url, {
            params: params
        }).then(res => {
            Toast.clear()
            resolve(res.data);
        }).catch(err => {
            Toast.clear()
            resolve(false);
            console.log({ url, params, err })
            errHandle(err, url)
        })
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params, options, loading = false) {
    if (loading) {
        Toast.show({
            icon: 'loading',
            content: '加载中…',
            duration: 0
        })
    }

    //设置请求头
    if (options) {
        HttpRequest = axios.create({
            withCredentials: true, //主要是添加这一行
            headers: options.headers
        })
    }

    return new Promise((resolve, reject) => {
        HttpRequest.post(url, params)
            .then(res => {
                Toast.clear()
                if (res.data['_ERROR_MESSAGE_']) {
                    Toast.show({
                        icon: 'fail',
                        content: res.data['_ERROR_MESSAGE_'],
                    })
                    resolve(false)
                    return
                }
                resolve(res.data);
            })
            .catch(err => {
                Toast.clear()
                console.log({ url, params, err })
                resolve(false);
                errHandle(err, url)
            })
    });
}

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-11 12:39:39
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { Divider, NavBar, FloatingBubble } from "antd-mobile";
import { MessageFill } from "antd-mobile-icons";
import "../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import { hospitalRecordsReportDetail } from '../../services/index'
import Pannel from "../../components/Pannel";
import Table from "../../components/Table";
import Skeleton from '../../components/Skeleton'

function ReportDetail() {
  const history = useHistory();
  let params = useParams();
  const { id } = params

  //诊疗计划
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  //查询我的住院列表
  const _hospitalRecordsReportDetail = async () => {
    const result = await hospitalRecordsReportDetail(id)
    setLoading(false)
    if (result) {
      setCurrentRecord(JSON.parse(result.ElectronicText.textData))
    }
  }

  useEffect(() => {
    _hospitalRecordsReportDetail()
  }, [id])

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">报告详情</div>
        </NavBar>
      </div>
      {
        loading ? <Skeleton /> : <div className="mdt-content mdt-bg-gray">
          <Pannel title="报告信息" data={currentRecord.pannelData} />
          <Table data={currentRecord.tableData} />
          <Divider>此报告仅供参考，以院内打印纸质报告为准</Divider>
          {/* <FloatingBubble
            axis="x"
            magnetic="x"
            style={{
              "--initial-position-bottom": "4rem",
              "--initial-position-right": "2rem",
              "--edge-distance": "24px",
            }}
            className="mdt-hover"
            onClick={() => {
              history.push("/chart/10000");
            }}
          >
            <MessageFill fontSize={32} />
          </FloatingBubble> */}
        </div>
      }
    </div>
  );
}

export default ReportDetail;

/*
 * @Author: your name
 * @Date: 2020-07-18 15:43:45
 * @LastEditTime: 2023-05-25 12:46:44
 * @LastEditors: lx.jin 308561217@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /FGMClient/apps/utils/serviceUrl.jsmdtSystemManage/
 */


const ServerUrl = {
    odataUrl: `/mdt/control/odatasvc/mdtManage`,
    odataSvcUrl: `/mdt/control/odataAppSvc/mdtManage`,
    odataSysUrl: `/mdt/control/odataAppSvc/mdtSystemManage`,
    api:`/mdt/control`,
    launchpad:'/mdt/control/odatasvc/launchpadManage'
};
export default ServerUrl;

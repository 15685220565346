/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-09 12:24:55
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { NavBar, ResultPage, Card, Empty, FloatingBubble } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import { mdtCamel } from '../../services/index'
import { UserSetOutline, AddOutline } from "antd-mobile-icons";

function MeetingFollow() {
    const history = useHistory();
    let params = useParams();
    const { id } = params

    //数据准备
    const [currentRecord, setCurrentRecord] = useState(null)
    const [loading, setLoading] = useState(true)

    //查询我的列表
    const _getMdtList = async () => {
        const result = window.localStorage.getItem("followDetail")
        if (result) {
            const record = JSON.parse(result)
            if (record) {
                const { MDT_C_D, HZ_XM, SF_DATE, SF_TYPE_N, FW_GX_N, FW_XM, SF_RM, HZ_XM_N, NEXT_SFRQ, SF_BRBQ_N } = record
                console.log({ record })
                setCurrentRecord({
                    details: [
                        {
                            label: "会诊时间",
                            value: MDT_C_D,
                            bold: true,
                        },
                        {
                            label: "预约随访日期",
                            value: NEXT_SFRQ,
                            bold: true,
                        },
                        {
                            label: "随访编号",
                            value: HZ_XM,
                            bold: true,
                        },

                        {
                            label: "随访类型",
                            value: SF_TYPE_N,
                            bold: true,
                        },
                        {
                            label: "随访人",
                            value: SF_RM,
                            bold: true,
                        },
                        {
                            label: "患者名称",
                            value: HZ_XM_N,
                            bold: true,
                        },
                        {
                            label: "受访人",
                            value: FW_XM,
                            bold: true,
                        },
                        {
                            label: "随访关系",
                            value: FW_GX_N,
                            bold: true,
                        },
                        {
                            label: "随访日期",
                            value: SF_DATE,
                            bold: true,
                        },
                        {
                            label: "随访病情",
                            value: SF_BRBQ_N,
                            bold: true,
                        },
                    ],
                    other: record
                })
            }
        }
    }

    useEffect(() => {
        _getMdtList()
    }, [])

    return (
        <div className="mdt-container">
            <div className="mdt-header">
                <NavBar
                    back="返回"
                    onBack={() => {
                        history.goBack();
                    }}
                >
                    <div className="title">随访详情</div>
                </NavBar>
            </div>
            <div className="mdt-content mdt-bg-gray">
                {
                    currentRecord ? <ResultPage
                        status="waiting"
                        title={<div style={{ fontSize: 15 }}>{currentRecord.other.HZ_XM_N}的随访</div>}
                        description={
                            <span style={{ fontSize: 32, color: "#ffffff" }}>随访记录</span>
                        }
                        icon={<UserSetOutline />}
                        details={currentRecord.details}
                    >
                        <Card title='随访内容：' style={{ marginTop: 12 }}>
                            {currentRecord.other.SF_NR}
                        </Card>
                    </ResultPage> : (
                        <Empty
                            style={{ marginTop: 200 }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据'
                        />
                    )
                }
                {/* <FloatingBubble
                    axis="x"
                    magnetic="x"
                    style={{
                        "--initial-position-bottom": "4rem",
                        "--initial-position-right": "2rem",
                        "--edge-distance": "24px",
                        zIndex: 999
                    }}
                    className="mdt-hover"
                    onClick={() => {
                        history.push(`/textArea/${currentRecord.other.ID}/sf`)
                    }}
                >
                    <AddOutline fontSize={32} />
                </FloatingBubble> */}
            </div>
        </div>
    );
}

export default MeetingFollow;

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-25 18:36:26
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Toast,
  List,
  NavBar,
  Empty
} from "antd-mobile";
import { ClockCircleOutline, RightOutline } from 'antd-mobile-icons'
import "../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import { mdtCamel } from '../../services/index'
import Skeleton from '../../components/Skeleton'

function MeetingTodo() {
  const history = useHistory();
  let params = useParams();
  const { id, ksid, userid } = params

  //数据准备
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  //查询我的住院列表
  const _getMdtListPending = async () => {
    let params

    if (id === 'doctor') {
      params = {
        serId: 'SER10000190',
        data: JSON.stringify({
          KS_ID: ksid,
          USR_ID: userid,
          HZ_ZT: '14fb1024-4111-4a35-bfb9-16bd49085e41'
        })
      }
    } else {
      params = {
        serId: 'SER10000180',
        data: JSON.stringify({
          HZ_ID: userid,
          HZ_ZT: '14fb1024-4111-4a35-bfb9-16bd49085e41'
        })
      }
    }

    const result = await mdtCamel(params)
    setLoading(false)
    if (result) {
      const record = JSON.parse(result.value)
      setCurrentRecord(record)
    }
  }

  useEffect(() => {
    _getMdtListPending()
  }, [id])

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">待加入会诊</div>
        </NavBar>
      </div>
      {
        loading ? <Skeleton /> : (
          <div className="mdt-content">
            {
              currentRecord && currentRecord.length > 0 ? (
                <div className="mdt-card">
                  {
                    currentRecord && currentRecord.map((item, index) => {
                      const { MDT_ID, MDT_CODE, HZ_ID, HZ_ZT_N, XM, MDT_C_D } = item
                      return (
                        <div key={`${MDT_ID}/${index}`} className="card-item">
                          <Card
                            title={
                              <div className="card-title">
                                <ClockCircleOutline
                                  className="card-icon"
                                  style={{
                                    color: "var(--adm-color-primary)",
                                  }}
                                />
                                {MDT_C_D}
                              </div>
                            }
                            extra={<RightOutline className="card-icon" />}
                            style={{ borderRadius: "16px" }}
                            onClick={() => {
                              history.push(`/meeting-detail/${MDT_ID}/${HZ_ID}`);
                            }}
                          >
                            <div className="card-content">
                              <List>
                                <List.Item>
                                  <div className="card-item-text">会诊编码：{MDT_CODE}</div>
                                </List.Item>
                                <List.Item>
                                  <div className="card-item-text">会诊发起：{XM}</div>
                                </List.Item>
                                <List.Item>
                                  <div className="card-item-text">会诊状态：{HZ_ZT_N}</div>
                                </List.Item>
                              </List>
                            </div>
                            <div className="card-footer" onClick={(e) => e.stopPropagation()}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  history.push(`/meeting-detail/${MDT_ID}/${HZ_ID}`);
                                }}
                                size="mini"
                              >
                                <span>参与会诊</span>
                              </Button>
                            </div>
                          </Card>
                        </div>
                      )
                    })
                  }
                </div>
              ) : <Empty
                style={{ marginTop: 200 }}
                imageStyle={{ width: 128 }}
                description='暂无数据'
              />
            }
          </div>
        )
      }
    </div>
  );
}

export default MeetingTodo;

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-06 18:03:43
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from 'react'
import "../../styles/index.css";
import { useHistory } from "react-router-dom";


function Index() {
    const history = useHistory();
    useEffect(() => {
        history.replace('/nav')
    }, [])

    return (
        <div className="mdt-container">
            <div className="mdt-header">
            </div>
            <div className="mdt-content mdt-paddar">
            </div>
            <div className="mdt-footer mdt-paddar"></div>
        </div>
    );
}

export default Index
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-25 18:12:06
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-09 12:25:08
 * @FilePath: /MDT-Client/src/components/Html5Scan.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Html5Qrcode } from "html5-qrcode";
import { useEffect } from "react";
import { Modal } from "antd-mobile";
import { useHistory } from "react-router-dom";
import "./index.css";

const Html5QrcodePlugin = (props) => {
  let html5QrCode, cameraId;
  const history = useHistory();

  const init = () => {
    Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices && devices.length) {
          if (devices.length > 1) {
            cameraId = devices[1].id;
          } else {
            cameraId = devices[0].id;
          }

          html5QrCode = new Html5Qrcode("reader");
          html5QrCode
            .start(
              cameraId,
              {
                fps: 100, // Optional, frame per seconds for qr code scanning
                qrbox: { width: 250, height: 250 }, // Optional, if you want bounded box UI
              },
              (decodedText, decodedResult) => {
                const { qrCodeSuccessCallback } = props;
                qrCodeSuccessCallback(decodedText, decodedResult);
              },
              (errorMessage) => {
                //console.log({ errorMessage });
              }
            )
            .catch((err) => {
              console.log({ err });
            });
        }
      })
      .catch((err) => {
        const { message } = err;
        if (message === "Permission denied") {
          Modal.confirm({
            title: "扫码需要您授予摄像头权限",
            content: `请关闭网页重新进入完成授权！`,
            onConfirm: async () => {
              window.close();
              history.goBack();
            },
            onCancel: async () => {
              history.goBack();
            },
          });
        }
      });
  };

  useEffect(() => {
    init();

    // cleanup function when component will unmount
    return () => {
      if (html5QrCode) {
        html5QrCode
          .stop()
          .then((ignore) => {
            // QR Code scanning is stopped.
          })
          .catch((err) => {
            // Stop failed, handle it.
          });
      }
    };
  }, []);

  return <div id="reader" />;
};

export default Html5QrcodePlugin;

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-30 07:58:48
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { List, NavBar, FloatingBubble, Card, Button, Empty, Tabs } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import { mdtCamel } from '../../services/index'
import { AddOutline, UserSetOutline, RightOutline } from "antd-mobile-icons";
import { useAliveController, useActivate, useUnactivate } from 'react-activation'

function MeetingFollow() {
    const history = useHistory();
    let params = useParams();
    const { id} = params
    const aliveController = useAliveController()


    //数据准备
    const [currentRecord, setCurrentRecord] = useState(null)
    const [loading, setLoading] = useState(true)
    const [activeKey, setActiveKey] = useState("1");//选中的tab

    //查询我的列表
    const _getList = async () => {
        let params = {
            serId: 'SER10000080',
            data: JSON.stringify({
                USER_ID: id,
                STATUS: activeKey==='1'?0:1
            })
        }

        const result = await mdtCamel(params)
        setLoading(false)
        if (result) {
            const record = JSON.parse(result.value)
            console.log({ record })
            setCurrentRecord(record)
        }
    }

    useActivate(() => {
        console.log('textAreasf')
        aliveController.drop(/textAreasf/)
    })

    useEffect(() => {
        _getList()
    }, [activeKey])

    const _renderTabs = () => {
        return (
            <Tabs
                style={{
                    "--title-font-size": "0.8rem",
                    "--content-padding": "0",
                }}
                activeKey={activeKey}
                onChange={(val) => {
                    setActiveKey(val);
                }}
            >
                <Tabs.Tab title="待完成随访" key="1" />
                <Tabs.Tab title="已完成随访" key="2" />
            </Tabs>
        )
    }

    return (
        <div className="mdt-container">
            <div className="mdt-header mdt-borderBottom">
                <NavBar
                    back="返回"
                    onBack={() => {
                        history.goBack();
                    }}
                >
                    <div className="title">随访列表</div>
                </NavBar>
            </div>
            <div className="mdt-content mdt-bg-gray">
                {_renderTabs()}
                <div className="mdt-content">
                    {
                        currentRecord && currentRecord.length > 0 ? (
                            <div className="mdt-card">
                                {
                                    currentRecord && currentRecord.map((item, index) => {
                                        const { HZ_XM, SF_DATE, HZ_XM_N, NEXT_SFRQ, SF_TYPE_N, ID } = item
                                        return (
                                            <div key={`${HZ_XM}/${index}`} className="card-item">
                                                <Card
                                                    title={
                                                        <div className="card-title">
                                                            {activeKey === '1' ? `预约随访时期：${ NEXT_SFRQ }` : `完成随访时期：${ SF_DATE }`}
                                                        </div>
                                                    }
                                                    extra={<RightOutline className="card-icon" />}
                                                    style={{ borderRadius: "16px" }}
                                                    onHeaderClick={() => {
                                                        window.localStorage.setItem("followDetail", JSON.stringify(item));
                                                        history.push(`/follow-detail`)
                                                    }}
                                                >
                                                    <div className="card-content">
                                                        <List>
                                                            <List.Item>
                                                                <div className="card-item-text">随访编码：{HZ_XM}</div>
                                                            </List.Item>
                                                            <List.Item>
                                                                <div className="card-item-text">随访类型：{SF_TYPE_N}</div>
                                                            </List.Item>
                                                            <List.Item>
                                                                <div className="card-item-text">随访患者：{HZ_XM_N}</div>
                                                            </List.Item>
                                                        </List>
                                                    </div>
                                                    {
                                                        activeKey === '1' && <div className="card-footer">
                                                            <Button
                                                                size="mini"
                                                                color='primary'
                                                                onClick={() => {
                                                                    history.push(`/textAreasf/${ID}/sf`)
                                                                }}
                                                            >
                                                                完成随访
                                                            </Button>
                                                        </div>
                                                    }
                                                </Card>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : <Empty
                            style={{ marginTop: 200 }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据'
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default MeetingFollow;

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-29 19:12:53
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect, useRef } from "react";
import { TextArea, NavBar, Button, SafeArea, Form, Input, Dialog, DatePicker, Selector, Toast } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams, } from "react-router-dom";
import dayjs from 'dayjs'
import { mdtCamel } from '../../services/index'
import { useAliveController, useActivate, useUnactivate } from 'react-activation'
function MdtForm() {
    const history = useHistory();
    const aliveController = useAliveController()
    let params = useParams();
    const { id, type } = params
    const [formRef] = Form.useForm()

    //数据准备
    const [currentRecord, setCurrentRecord] = useState(null)
    const [loading, setLoading] = useState(true)

    const getTitle = () => {
        let result
        switch (type) {
            case 'sf':
                result = '完成随访'
                break;
            case 'hzpj':
                result = '新增会诊诊断'
                break;
            default:
                break;
        }
        return result
    }

    const _render = () => {
        switch (type) {
            case 'sf':
                return (
                    currentRecord && <Form
                        name='form'
                        form={formRef}
                        requiredMarkStyle='asterisk'
                        footer={
                            <Button block color='primary' type='submit' size='middle'>
                                确定提交
                            </Button>
                        }
                        onFinish={(values) => {
                            Dialog.confirm({
                                content: '是否提交申请',
                                onConfirm: async () => {
                                    const data = {
                                        ID: id,
                                        MODIFYID: window.localStorage.getItem("mdtExternalId"),//记录修改人ID
                                        MODIFY_DATE: dayjs(new Date()).format('YYYY-MM-DD'),
                                        ...values,
                                        FW_GX: values.FW_GX[0],
                                        SF_BRBQ: values.SF_BRBQ[0],
                                        // SF_TYPE: values.SF_TYPE[0],
                                        SF_DATE: dayjs(values.SF_DATE).format('YYYY-MM-DD'),
                                        // NEXT_SFRQ: dayjs(values.NEXT_SFRQ).format('YYYY-MM-DD')
                                        STATUS:1
                                    }
                                    console.log({ data })
                                    const params = {
                                        serId: 'SER10000100',
                                        data: JSON.stringify(data)
                                    }
                                    const result = await mdtCamel(params)
                                    if (result) {
                                        Toast.show({
                                            icon: 'success',
                                            content: '提交成功',
                                            position: 'bottom',
                                        })
                                        history.goBack();
                                        formRef.resetFields()
                                    }
                                },
                            })
                        }}
                    >
                        {/* <Form.Item name='SF_TYPE' label='随访类型'>
                            <Selector
                                columns={3}
                                options={currentRecord.SF_TYPE}
                            />
                        </Form.Item> */}
                        <Form.Item
                            name='SF_DATE'
                            label='随访时间'
                            trigger='onConfirm'
                            rules={[{ required: true }]}
                            onClick={(e, datePickerRef) => {
                                datePickerRef.current?.open()
                            }}
                        >
                            <DatePicker>
                                {value =>
                                    value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                                }
                            </DatePicker>
                        </Form.Item>
                        {/* <Form.Item
                            name='NEXT_SFRQ'
                            label='预约随访时间'
                            trigger='onConfirm'
                            onClick={(e, datePickerRef) => {
                                datePickerRef.current?.open()
                            }}
                        >
                            <DatePicker>
                                {value =>
                                    value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                                }
                            </DatePicker>
                        </Form.Item> */}
                        <Form.Item name='FW_GX' label='随访关系' rules={[{ required: true }]}>
                            <Selector
                                columns={3}
                                options={currentRecord.FW_GX}
                            />
                        </Form.Item>
                        <Form.Item name='FW_XM' label='受访人' rules={[{ required: true }]}>
                            <Input placeholder='请输入' />
                        </Form.Item>
                        <Form.Item name='SF_BRBQ' label='随访病情' rules={[{ required: true }]}>
                            <Selector
                                columns={3}
                                options={currentRecord.SF_BRBQ}
                            />
                        </Form.Item>
                        <Form.Item name='SF_NR' label='随访内容' rules={[{ required: true }]}>
                            <TextArea
                                placeholder='请输入'
                                maxLength={100}
                                rows={3}
                                showCount
                            />
                        </Form.Item>
                    </Form>
                )
            case 'hzpj':
                return (
                    <Form
                        name='form'
                        form={formRef}
                        requiredMarkStyle='asterisk'
                        footer={
                            <Button block color='primary' type='submit' size='middle'>
                                确定提交
                            </Button>
                        }
                        onFinish={(values) => {
                            Dialog.confirm({
                                content: '是否确定提交',
                                onConfirm: async () => {
                                    const data = {
                                        ...values,
                                        MDT_ID: id,
                                        USER_ID: window.localStorage.getItem("mdtExternalId"),
                                        C_DATE: dayjs(values.C_DATE).format('YYYY-MM-DD'),
                                        COLUMN_2: values.COLUMN_2.split('--')[1],
                                        COLUMN_1: values.COLUMN_1 ? values.COLUMN_1 : ' ',
                                        COLUMN_3: values.COLUMN_3 ? values.COLUMN_3 : ' '
                                    }
                                    console.log({ data })
                                    const params = {
                                        serId: 'SER10000270',
                                        data: JSON.stringify(data)
                                    }
                                    const result = await mdtCamel(params)
                                    if (result) {
                                        Toast.show({
                                            icon: 'success',
                                            content: '提交成功',
                                            position: 'bottom',
                                        })
                                        window.mdtGobackData = {
                                            type: 'zdjy'
                                        }
                                        history.goBack();
                                        formRef.resetFields()
                                    }
                                },
                            })
                        }}
                    >
                        <Form.Item
                            name='C_DATE'
                            label='参加时间'
                            trigger='onConfirm'
                            onClick={(e, datePickerRef) => {
                                datePickerRef.current?.open()
                            }}
                        >
                            <DatePicker>
                                {value =>
                                    value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                                }
                            </DatePicker>
                        </Form.Item>
                        <Form.Item name='COLUMN_1' label='诊断前缀' >
                            <Input placeholder='请输入' />
                        </Form.Item>
                        <Form.Item
                            name='COLUMN_2'
                            label='诊断'
                            rules={[{ required: true }]}
                            trigger='onConfirm'
                            onClick={(e) => {
                                history.push(`/searchView/zd`);
                            }}
                        >
                            <Input placeholder='请选择诊断类型' />
                        </Form.Item>

                        <Form.Item name='COLUMN_3' label='诊断后缀' >
                            <Input placeholder='请输入' />
                        </Form.Item>
                        <Form.Item name='HZ_YJ' label='会诊意见' rules={[{ required: true }]}>
                            <TextArea
                                placeholder='请输入'
                                maxLength={100}
                                rows={3}
                                showCount
                            />
                        </Form.Item>
                    </Form>
                )
            default:
                break;
        }
    }

    //查询随访下拉数据
    const _getSfData = async () => {
        // let SF_TYPE = await mdtCamel({
        //     serId: 'SER10000250',
        //     data: JSON.stringify({
        //         TYPE: 'sftype',
        //     })
        // })
        let FW_GX = await mdtCamel({
            serId: 'SER10000250',
            data: JSON.stringify({
                TYPE: 'sfgx',
            })
        })
        let SF_BRBQ = await mdtCamel({
            serId: 'SER10000250',
            data: JSON.stringify({
                TYPE: 'sfbq',
            })
        })
        setLoading(false)
        if (FW_GX && SF_BRBQ) {
            //SF_TYPE = JSON.parse(SF_TYPE.value)
            FW_GX = JSON.parse(FW_GX.value)
            SF_BRBQ = JSON.parse(SF_BRBQ.value)

            const arr1 = [], arr2 = [], arr3 = []
            // SF_TYPE.map((item) => {
            //     const { VALUE, CODE } = item
            //     arr1.push({ label: VALUE, value: CODE })
            // })
            FW_GX.map((item) => {
                const { VALUE, CODE } = item
                arr2.push({ label: VALUE, value: CODE })
            })
            SF_BRBQ.map((item) => {
                const { VALUE, CODE } = item
                arr3.push({ label: VALUE, value: CODE })
            })
            const record = {
                FW_GX: arr2,
                SF_BRBQ: arr3
            }
            setCurrentRecord(record)
        }
    }

    useActivate(() => {
        if (window.mdtGobackData?.type === 'zd') {
            const { label, value } = window.mdtGobackData.data
            if (label && value) {
                formRef.setFieldValue('COLUMN_2', `${label}--${value}`)
            }
            window.mdtGobackData = null
        }
    })

    useEffect(() => {
        type === 'sf' && _getSfData()
    }, [])

    return (
        <div className="mdt-container">
            <div className="mdt-header mdt-borderBottom">
                <NavBar
                    back="返回"
                    onBack={() => {
                        history.goBack();
                        formRef.resetFields()
                    }}
                >
                    <div className="title">{getTitle()}</div>
                </NavBar>
            </div>
            <div className="mdt-content mdt-bg-gray mdt-paddar-small">
                {_render()}
            </div>
            <div className="mdt-paddar-small">
                <SafeArea position='bottom' />
            </div>
        </div>
    );
}

export default MdtForm;

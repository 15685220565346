/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-19 14:56:42
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-04 11:27:55
 * @FilePath: /MDT-Client/src/components/Table.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import { Image, Space } from "antd-mobile";
import "./index.css";

const Table = (props) => {
  const { data } = props;
  const { header, rows } = data;

  const _renderBool = (value) => {
    return value&&<Image src={require("../assets/shang.png")} width={20} height={20}/>;
  };

  return (
    <div className="mdt-table">
      <div className="mdt-table-header">
        {header.map((item, index) => {
          const { width, text,align } = item;
          return (
            <div
              key={`mdt-table-header-text${index}`}
              style={{ width: width, textAlign: align }}
              className="mdt-table-header-text"
            >
              {text}
            </div>
          );
        })}
      </div>
      <div className="mdt-table-rows">
        {rows.map((item, index) => {
          return (
            <div className="mdt-table-row" key={`mdt-table-rows${index}`}>
              {item.map((citems, cindex) => {
                const { type, value } = citems;
                return (
                  <div
                    key={`mdt-table-row${index}-${cindex}`}
                    style={{
                      width: header[cindex].width,
                      textAlign: header[cindex].align,
                    }}
                  >
                    {type === "text" ? (
                      <span>{value}</span>
                    ) : (
                      _renderBool(value)
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Table;

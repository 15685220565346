/* eslint-disable react-hooks/exhaustive-deps */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:44:59
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-05 16:23:19
 * @FilePath: /MDT-Client/src/pages/user/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { ResultPage, Card, NavBar } from "antd-mobile";
import { UserSetOutline } from "antd-mobile-icons";
import { useHistory, useParams } from "react-router-dom";
import "../../styles/index.css";
import Table from "../../components/Table";
import { hospitalRecords } from '../../services/index'
import Skeleton from '../../components/Skeleton'

function Info() {
  const history = useHistory();
  let params = useParams();
  const { id } = params

  //住院医嘱
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  //基本信息
  const [baseInfo, setBaseInfo] = useState(null)

  //查询我的住院列表
  const _hospitalRecords = async () => {
    const result = await hospitalRecords(id)
    setLoading(false)
    if (result) {
      const { DoctorsAdviceInHospital, createdDate, Patient, DoctorTeam } = result
      const details = [
        {
          label: "所属科室",
          value: DoctorTeam?.departmentName,
          bold: true,
        },
        {
          label: "联系方式",
          value: Patient?.PartyAndContact?.phoneNumber,
        },
        {
          label: "医保卡号",
          value: Patient?.medicalInsuranceCardNo,
        },
        {
          label: "住院日期",
          value: createdDate,
        },
      ];
      setBaseInfo({
        details,
        patientName: Patient.patientName
      })
      if (DoctorsAdviceInHospital) {
        const { textData } = DoctorsAdviceInHospital
        setCurrentRecord(textData)
      }
    }
  }

  useEffect(() => {
    _hospitalRecords()
  }, [id])

  return (
    <div className="mdt-container">
      <div className="mdt-header">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">住院信息</div>
        </NavBar>
      </div>
      {
        loading ? <Skeleton /> : (
          <div className="mdt-content mdt-bg-gray">
            {
              baseInfo && <ResultPage
                status="waiting"
                title={<div style={{ fontSize: 15 }}>{baseInfo.patientName}</div>}
                description={
                  <span style={{ fontSize: 32, color: "#ffffff" }}>住院医嘱</span>
                }
                icon={<UserSetOutline />}
                details={baseInfo.details}
              >
                {currentRecord && <Card title="入院医嘱">
                  <Table data={JSON.parse(currentRecord)} />
                </Card>}
              </ResultPage>
            }
          </div>
        )
      }
    </div>
  );
}

export default Info;

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-01 13:52:07
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { List, NavBar } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import { mdtCamel } from '../../services/index'

function MeetingFollow() {
  const history = useHistory();
  let params = useParams();
  const { id, ksid, userid } = params

  //数据准备
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  //查询我的列表
  const _getMdtList = async () => {
    let params

    if (id === 'doctor') {
      params = {
        serId: 'SER10000190',
        data: JSON.stringify({
          KS_ID: ksid,
          USR_ID: userid,
          HZ_ZT: '001846c9-b5ef-4840-bc56-7bc756212b35'
        })
      }
    } else {
      params = {
        serId: 'SER10000180',
        data: JSON.stringify({
          HZ_ID: userid,
          HZ_ZT: '001846c9-b5ef-4840-bc56-7bc756212b35'
        })
      }
    }

    const result = await mdtCamel(params)
    setLoading(false)
    if (result) {
      const record = JSON.parse(result.value)
      console.log({ followList: record })
      setCurrentRecord(record)
    }
  }

  useEffect(() => {
    _getMdtList()
  }, [])

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">待随访会诊列表</div>
        </NavBar>
      </div>
      <div className="mdt-content">
        <List header="">
          {
            currentRecord && currentRecord.map((item, index) => {
              const { MDT_ID, MDT_CODE, HZ_ID, MDT_TYPE_N, MDT_ADDR_N, MDT_ZZ_N, XM, MDT_C_D } = item
              return (
                <List.Item
                  key={`${MDT_ID}/${index}`}
                  description={`会诊时间：${MDT_C_D}`}
                  onClick={() => {
                    history.push(`/follow-list/${MDT_ID}`)
                  }}
                  extra={MDT_CODE}
                >
                  {XM} {MDT_TYPE_N}
                </List.Item>
              )
            })
          }
        </List>
      </div>
    </div>
  );
}

export default MeetingFollow;

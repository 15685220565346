/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-02 12:33:34
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { Image, NavBar, SearchBar, List, Empty } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams, } from "react-router-dom";
import { mdtCamel, queryDicts } from '../../services/index'

const image =
    "https://dwdw.oss-cn-hangzhou.aliyuncs.com/test/%E6%89%8B%E5%86%8C.jpeg";
function WebView() {
    const history = useHistory();
    let params = useParams();
    const { url } = params

    return (
        <div className="mdt-container">
            <div className="mdt-header mdt-borderBottom">
                <NavBar
                    back="返回"
                    onBack={() => {
                        history.goBack();
                    }}
                >
                    <div className="title">在线视频</div>
                </NavBar>
            </div>
            <div className="mdt-content">
                <object style={{ height: '100%', fontSize:10 }} data={`https://ywpt.lxmeeting.com/webrtc/?${url}`}></object>
            </div>
        </div>
    );
}

export default WebView;

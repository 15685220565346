/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-31 10:05:01
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import { Image, NavBar } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams, } from "react-router-dom";

const image =
  "https://dwdw.oss-cn-hangzhou.aliyuncs.com/test/%E6%89%8B%E5%86%8C.jpeg";
function ImageDetail() {
  const history = useHistory();

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">详情页</div>
        </NavBar>
      </div>
      <div className="mdt-content">
        <div className="mdt-image-long">
          <Image src={image} fit="fill" />
        </div>
      </div>
    </div>
  );
}

export default ImageDetail;

/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-02 07:14:34
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { List, NavBar, Dropdown, Radio, Space, Empty, Card } from "antd-mobile";
import { RightOutline } from 'antd-mobile-icons'
import "../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import { mdtCamel } from '../../services/index'
import Skeleton from '../../components/Skeleton'
import { useAliveController, useActivate, useUnactivate } from 'react-activation'


function MeetingList() {
  const history = useHistory();
  let params = useParams();
  const aliveController = useAliveController()
  const { id, ksid, userid, type } = params

  //数据准备
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  //查询我的列表
  const _getMdtList = async () => {
    let params, HZ_ZT
    /**
     * 状态：
          ----会诊：14fb1024-4111-4a35-bfb9-16bd49085e41
          ----评价：6f985ce1-dd7d-4821-b449-b6dd9c345f37
          ----归档：001846c9-b5ef-4840-bc56-7bc756212b35
     */
    switch (type) {
      case 'gd':
        HZ_ZT = `001846c9-b5ef-4840-bc56-7bc756212b35`
        break;
      case 'pj':
        HZ_ZT = `6f985ce1-dd7d-4821-b449-b6dd9c345f37`
        break;
      case 'zl':
        HZ_ZT = `14fb1024-4111-4a35-bfb9-16bd49085e41`
        break;
      default:
        break;
    }

    if (id === 'doctor') {
      params = {
        serId: 'SER10000190',
        data: JSON.stringify({
          // KS_ID: ksid,保持院内端一致
          YS_USER_ID: userid,
          HZ_ZT,
          // START_DATE: `2023-01-01`,
          // END_DATE: `2023-12-31`
        })
      }
    } else {
      params = {
        serId: 'SER10000180',
        data: JSON.stringify({
          HZ_ID: userid,
          HZ_ZT,
          // START_DATE: `2023-01-01`,
          // END_DATE: `2023-12-31`
        })
      }
    }

    const result = await mdtCamel(params)
    setLoading(false)
    if (result) {
      const record = JSON.parse(result.value)
      console.log({ meetingList:record })
      setCurrentRecord(record)
    }
  }

  //title
  const getTitle = () => {
    let result
    switch (type) {
      case 'gd':
        result = `会诊归档`
        break;
      case 'pj':
        result = `会诊评价`
        break;
      case 'zl':
        result = `会诊诊疗`
        break;
      default:
        break;
    }
    return result
  }

  useActivate(() => {
    console.log('TestFunction: didActivate')
    aliveController.drop(/meetingDetail/)
  })

  useEffect(() => {
    _getMdtList()
  }, [])

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">{getTitle()}</div>
        </NavBar>
      </div>
      <div className="mdt-content">
        <Dropdown>
          <Dropdown.Item key='sorter' title='排序'>
            <div style={{ padding: 12 }}>
              <Radio.Group defaultValue='1'>
                <Space direction='vertical' block>
                  <Radio block value='1'>
                    由近到远
                  </Radio>
                  <Radio block value='2'>
                    由远到近
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </Dropdown.Item>
          <Dropdown.Item key='bizop' title='时期筛选'>
            <div style={{ padding: 12 }}>
              <Radio.Group defaultValue='1'>
                <Space direction='vertical' block>
                  <Radio block value='1'>
                    近三个月
                  </Radio>
                  <Radio block value='2'>
                    近半年
                  </Radio>
                  <Radio block value='3'>
                    近一年
                  </Radio>
                  <Radio block value='4'>
                    近三年
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </Dropdown.Item>
        </Dropdown>
        {
          loading ? <Skeleton /> : (
            <div className="mdt-content">
              {
                currentRecord && currentRecord.length > 0 ? (
                  <div className="mdt-card">
                    {
                      currentRecord && currentRecord.map((item, index) => {
                        const { MDT_ID, MDT_CODE, HZ_ID, MDT_TYPE_N, MDT_ADDR_N, MDT_ZZ_N, HZ_ZT_N } = item
                        return (
                          <div key={`${MDT_ID}/${index}`} className="card-item">
                            <Card
                              title={
                                <div className="card-title">
                                  会诊编码：{MDT_CODE}
                                </div>
                              }
                              extra={<RightOutline className="card-icon" />}
                              style={{ borderRadius: "16px" }}
                              onClick={() => {
                                history.push(`/meeting-detail/${MDT_ID}/${HZ_ID}/${type}`);
                              }}
                            >
                              <div className="card-content">
                                <List>
                                  <List.Item>
                                    <div className="card-item-text">会诊类型：{MDT_TYPE_N}</div>
                                  </List.Item>
                                  <List.Item>
                                    <div className="card-item-text">会诊地址：{MDT_ADDR_N}</div>
                                  </List.Item>
                                  <List.Item>
                                    <div className="card-item-text">会诊主旨：{MDT_ZZ_N}</div>
                                  </List.Item>
                                  <List.Item>
                                    <div className="card-item-text">会诊状态：{HZ_ZT_N}</div>
                                  </List.Item>
                                </List>
                              </div>
                            </Card>
                          </div>
                        )
                      })
                    }
                  </div>
                ) : <Empty
                  style={{ marginTop: 200 }}
                  imageStyle={{ width: 128 }}
                  description='暂无数据'
                />
              }
            </div>
          )
        }
      </div>
    </div>
  );
}

export default MeetingList;

/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-05-06 14:36:35
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-05 16:22:12
 * @FilePath: /MDT-Client/src/services/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable import/no-anonymous-default-export */
import { post, get } from './request'
import ServerUrl from './serviceUrl'

//账号密码登录
export const login = (params) => {
  return post(`${ServerUrl.api}/encryptLogin`, params, {}, true);
}

//账号密码登录
export const logout = () => {
  return post(`${ServerUrl.api}/logout`)
}

//二维码登录
export const qrLogin = (params) => {
  return post(`${ServerUrl.api}/qrLogin`, params, {}, true)
}

//查询咨询消息
export const getBannerList = () => {
  return get(`${ServerUrl.odataSysUrl}/BannerRoots(%27BANNER_10000%27)/Banner?$count=true&$select=createdDate,contentId&$expand=ImageDataResource&$top=10&$skip=0`)
}

//查询我的用户信息
export const queryMe = () => {
  return get(
    `${ServerUrl.launchpad}/Me?$expand=PartyRole,Person,SecurityPermission`
  );
};

//查询咨询消息
export const getMsg = (chatId, sequence) => {
  return get(`${ServerUrl.api}/getMsg?chatId=${chatId}&sequence=${sequence}`)
}

//查询咨询全部消息
export const getMsgAll = (workEffortId) => {
  return get(`${ServerUrl.api}/getMsg?workEffortId=${workEffortId}`)
}

//发送住院咨询消息
export const sendMsg = (params) => {
  const options = {
    headers: {
      'Content-Type': "multipart/form-data"
    }
  }

  return post(`${ServerUrl.api}/sendMsg`, params, options)
}

//标记患者已读
export const readMsg = (params) => {
  return post(`${ServerUrl.api}/readMsg`, params, {})
}

//查询我的会话列表
export const getChatList = (id) => {
  const url = id ? `${ServerUrl.api}/getChatList?workEffortId=${id}` : `${ServerUrl.api}/getChatList`
  return get(url)
}

//查询会话历史数据
export const getHistoryMsg = (id, sequence) => {
  const url = sequence ? `${ServerUrl.api}/getHistoryMsg?chatId=${id}&top=10&sequence=${sequence}` : `${ServerUrl.api}/getHistoryMsg?chatId=${id}&top=10`
  return get(url)
}

//查询患者的住院信息列表
export const patientHospitalRecordList = (params) => {
  return get(`${ServerUrl.odataUrl}/Me/PatientHospitalRecordList?$select=workEffortName,workEffortId&$orderby=createdDate desc&$filter=currentStatusId eq 'BEING_HOSPITALIZED'`, params,true)
}

//查询患者的住院信息详情
export const hospitalRecords = (id) => {
  return get(
    `${ServerUrl.odataUrl}/HospitalRecords('${id}')?$select=createdDate&$expand=Patient($select=patientName,medicalInsuranceCardNo;$expand=PartyAndContact($select=phoneMobile)),DoctorTeam($select=departmentName),DoctorsAdviceInHospital`
  );
}

//查询患者的住院诊疗计划
export const hospitalRecordsPlan = (id) => {
  return get(`${ServerUrl.odataUrl}/HospitalRecords('${id}')/TreatmentPlan?$select=workEffortName,createdDate,showAsEnumId`)
}

//查询患者的住院报告列表
export const hospitalRecordsReport = (id) => {
  return get(`${ServerUrl.odataUrl}/HospitalRecords('${id}')/TreatmentReport?$select=contentName,createdDate`)
}

//查询患者的住院报告详情
export const hospitalRecordsReportDetail = (id) => {
  return get(`${ServerUrl.odataUrl}/TreatmentReports('${id}')?$expand=ElectronicText`)
}

//查询会诊资料url
export const dataResourceUrl = (id) => {
  return get(`${ServerUrl.odataUrl}/DataResource('${id}')?$select=objectInfo`)
}

//查询会诊资料url
export const meetingSafeEncode = (params) => {
  const options = {
    headers: {
      'Content-Type': "application/json"
    }
  }

  return post(`${ServerUrl.odataSvcUrl}/meetingSafeEncode`, params, options, true)
}

//查询字典从表
export const queryDicts = (type,keyword,top=100) => {
  const url = keyword ? `${ServerUrl.odataUrl}/Dicts?$filter=type eq '${type}' and contains(value,'${keyword}')&$top=${top}` : `${ServerUrl.odataUrl}/Dicts?$filter=type eq '${type}'&$top=${top}`
  return get(url)
}

/***************************************************会诊相关业务*******************************************************/

//mdtCamel
export const mdtCamel = (params) => {
  return post(`${ServerUrl.odataSvcUrl}/mdtCamel`, params, {}, true);
}

//查询会诊归档
export const getMdtList = (navigation) => {
  return get(`${ServerUrl.odataUrl}/getMdtList(START_DATE='2000-01-01',END_DATE='2023-05-23')`)
}

//查询会诊待办
export const getMdtListPending = (navigation) => {
  return get(`${ServerUrl.odataUrl}/getMdtList(START_DATE='2000-01-01',END_DATE='2023-05-23')`)
}

//查询会诊患者信息
export const getPatientInfo = (id) => {
  return get(`${ServerUrl.odataUrl}/getPatientInfo(HZ_ID='${id}')
`)
}

//查询会诊视频信息
export const consultationVideoConsultation = (id) => {
  return get(`${ServerUrl.odataUrl}/getMdtMeetingInfo(MDT_ID='${id}')`)
}

//查询会诊资料
export const consultationDataResource = (id) => {
  return get(`${ServerUrl.odataUrl}/getMdtInfo(MDT_ID='${id}')`)
}

//获取医院团队列表
export const getTeamList = () => {
  return get(`${ServerUrl.odataSvcUrl}/getTeamList(JG_C='HS')`)
}

//获取医院团队列表
export const getTeamPeopleList = (id) => {
  return get(`${ServerUrl.odataSvcUrl}/getTeamPeopleList(KS_ID='${id}')`)
}


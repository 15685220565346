/* eslint-disable no-const-assign */
/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-06-01 17:29:09
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useRef, useState } from "react";
import { List, Image, Tag, NavBar, FloatingBubble, Space, Button, Empty, Badge } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import { MessageFill, UserContactOutline, FileOutline } from "antd-mobile-icons";
import { getChatList } from '../../services/index'
import Skeleton from '../../components/Skeleton'
import { useAliveController, useActivate, useUnactivate } from 'react-activation'

function ChartList() {
  const history = useHistory();
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(true)

  //查询标记后的咨询数据
  const _getChatList = async () => {
    const result = await getChatList()
    setLoading(false)
    if (result) {
      const { list } = result
      setCurrentRecord(list)
    }
  }

  useActivate(() => {
    console.log('TestFunction: didActivate')
    _getChatList()
  })

  useUnactivate(() => {
    console.log('TestFunction: willUnactivate')
  })

  useEffect(() => {
    if (!currentRecord) {
      _getChatList()
    }
  }, [])

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">咨询列表</div>
        </NavBar>
      </div>
      {
        loading ? <Skeleton /> : <div className="mdt-content">
          {
            currentRecord && currentRecord.length === 0 ? <Empty
              style={{ marginTop: 200 }}
              imageStyle={{ width: 128 }}
              description='暂无数据'
            /> : (
              <List>
                {currentRecord && currentRecord.map((user) => {
                  const { title, workEffortId, chatId, dateTime, msgStatus } = user
                  return (
                    <div key={workEffortId}>
                      <List.Item
                        prefix={
                          <Badge content={msgStatus === '2' ? '1' : null}>
                            <Image
                              src={`https://img2.baidu.com/it/u=2370366942,2724449755&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=797`}
                              style={{ borderRadius: 20 }}
                              fit="cover"
                              width={40}
                              height={40}
                            />
                          </Badge>
                        }
                        extra='进入聊天'
                        description={dateTime}
                        onClick={() => {
                          history.push(`/chart/${workEffortId}/doctor/${chatId}`);
                        }}
                      >
                        {title}
                      </List.Item>
                      <div className="mdt-paddar-mini-b mdt-paddar-small-lr">
                        <Space wrap>
                          <Button color='primary' fill='outline' size="mini" shape='rounded' onClick={() => {
                            history.push(`/hospital-info/${workEffortId}`);
                          }}>
                            <Space>
                              <UserContactOutline />
                              <span>住院信息</span>
                            </Space>
                          </Button>
                          <Button color='primary' fill='solid' size="mini" shape='rounded' onClick={() => {
                            history.push(`/hospital-report/${workEffortId}`);
                          }}>
                            <Space>
                              <FileOutline />
                              <span>住院报告</span>
                            </Space>
                          </Button>
                        </Space>
                      </div>
                    </div>
                  )
                })}
              </List>
            )
          }
        </div>
      }
    </div>
  );
}

export default ChartList;

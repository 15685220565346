/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: lx.jin 308561217@qq.com
 * @Date: 2023-04-13 11:10:26
 * @LastEditors: lx.jin 308561217@qq.com
 * @LastEditTime: 2023-05-25 17:32:31
 * @FilePath: /MDT-Client/src/pages/tabbar/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { List, Image, Tag, NavBar, Collapse } from "antd-mobile";
import "../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import { getTeamList, getTeamPeopleList, mdtCamel } from '../../services/index'
import Skeleton from '../../components/Skeleton'

function Team() {
  const history = useHistory();
  let params = useParams();
  const { id } = params

  //数据准备
  const [currentRecord, setCurrentRecord] = useState(null)
  const [currentKsRecord, setCurrentKsRecord] = useState(null)

  const [loading, setLoading] = useState(true)

  //查询我的住院列表
  const _getTeamList = async () => {
    const params={
      serId:'SER10000110',
      data:JSON.stringify({
        JG_C:'HS'
      })
    }
    const result = await mdtCamel(params)
    setLoading(false)
    if (result) {
      const record = JSON.parse(result.value)
      setCurrentRecord(record)
    }
  }

  //查询我的住院列表
  const _getTeamPeopleList = async (id) => {
    const params = {
      serId: 'SER10000120',
      data: JSON.stringify({
        KS_ID: id
      })
    }
    const result = await mdtCamel(params)
    if (result) {
      const record = JSON.parse(result.value)
      setCurrentKsRecord({
        id,
        record
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    _getTeamList()
  }, [id])

  return (
    <div className="mdt-container">
      <div className="mdt-header mdt-borderBottom">
        <NavBar
          back="返回"
          onBack={() => {
            history.goBack();
          }}
        >
          <div className="title">团队介绍</div>
        </NavBar>
      </div>
      {
        loading ? <Skeleton /> : (
          <div className="mdt-content">
            <Collapse accordion>
              {
                currentRecord && currentRecord.map((item, index) => {
                  const { KS_NAME, KS_ID } = item
                  return (
                    <Collapse.Panel key={`Collapse${index}`} title={KS_NAME} onClick={() => {
                      setCurrentKsRecord(null)
                      if (currentKsRecord) {
                        const { id, record } = currentKsRecord
                        if (id !== KS_ID) {
                          _getTeamPeopleList(KS_ID)
                        }
                      } else {
                        _getTeamPeopleList(KS_ID)
                      }
                    }}>
                      <List >
                        {currentKsRecord && currentKsRecord.record.map((user) => {
                          const { USER_ID, XM, GW_C_N } = user
                          return (
                            <List.Item
                              key={USER_ID}
                              prefix={
                                <Image
                                  src={`https://img.zcool.cn/community/0117825857a100a801219c778d2554.jpg@1280w_1l_2o_100sh.jpg`}
                                  style={{ borderRadius: 20 }}
                                  fit="cover"
                                  width={40}
                                  height={40}
                                />
                              }
                              description={`这是一段描述信息`}
                            >
                              <div className="mdt-tag">
                                {XM}
                                <Tag color="primary" fill="outline">
                                  {GW_C_N}
                                </Tag>
                              </div>
                            </List.Item>
                          )
                        })}
                      </List>
                    </Collapse.Panel>
                  )
                })
              }
            </Collapse>
          </div>
        )
      }
    </div>
  );
}

export default Team;
